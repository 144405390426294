<template>
  <div class="animated fadeIn">
    <div v-if="loadingAdjusmtent" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <b-form v-else novalidate :validated="isValid" autocomplete="off">
      <div v-if="formData && formData.id" class="row justify-content-between">
        <div class="col-auto">
          <h4 class="ml-md-0 ml-3">
            Documento {{ formData.number }}
            <small v-if="formData.generatedFromOrder" class="text-muted">
              ( Gerado a partir do pedido
              {{ formData.generatedFromOrder.number }}
              <span
                v-if="formData.generatedFromOrder.fiscalOperation"
                class="badge"
                :class="operationBadgeClassObject(formData.generatedFromOrder)"
              >
                {{ formData.generatedFromOrder.fiscalOperation.showType }}
              </span>
              )
            </small>
          </h4>
        </div>
        <div class="col-sm-auto col-12">
          <b-button
            variant="secondary"
            @click="openOrderAttendancesModal"
            class="mr-md-0 mr-3 ml-sm-0 ml-3"
          >
            Apontamentos
          </b-button>
        </div>
      </div>

      <div v-if="formData.processStatus" class="d-flex">
        <div class="mr-3">
          <span class="font-weight-bold">Status: </span>
          <stock-adjustment-process-status-badge
            :stockAdjustment="formData"
          ></stock-adjustment-process-status-badge>
        </div>

        <div
          v-if="
            formData.stockAdjustmentItemsLength &&
            formData.numberOfItemsAdded &&
            formData.numberOfItemsAdded < formData.stockAdjustmentItemsLength
          "
          class="d-flex align-items-center"
        >
          <span class="font-weight-bold mr-1">Progresso: </span>
          <b-progress
            :max="formData.stockAdjustmentItemsLength"
            style="min-width: 300px"
          >
            <b-progress-bar :value="formData.numberOfItemsAdded">
              {{ formData.numberOfItemsAdded }} /
              {{ formData.stockAdjustmentItemsLength }}
            </b-progress-bar>
          </b-progress>
        </div>

        <button v-if="formData.processStatusDescription && formData.processStatusDescription.length"
          type="button" class="btn btn-sm btn-outline-info ml-3" @click="showProcessStatusModal = true">
          Detalhes
        </button>
      </div>

      <b-row class="mx-md-n3 mx-1 mt-3">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
          <label for="warehouseSelect">Selecione o Armazém do Ajuste:</label>
          <div v-if="loadingWarehouses">
            <animation />
          </div>
          <model-list-select
            v-else
            id="warehouseSelect"
            :list="warehouses"
            option-value="id"
            option-text="name"
            v-model="selectedWarehouse"
            placeholder="Armazém"
            :isDisabled="!allowEdit || formData.movementStatus === 'C'"
          />
        </div>
      </b-row>

      <div class="row px-lg-0 px-3">
        <div class="col-12">
          <label>Quais endereços você deseja contar?</label>

          <b-form-radio-group
            v-model="formData.countOption"
            @change="reloadFormData({ countOption: $event })"
            :disabled="!allowEdit || formData.movementStatus === 'C'"
          >
            <b-form-radio name="countOptions" value="E" class="my-2">
              Endereços específicos
            </b-form-radio>
            <b-form-radio name="countOptions" value="G">
              Todos os endereços de um grupo
            </b-form-radio>
            <b-form-radio name="typeOptions" value="W">
              Todos os endereços do armazém
            </b-form-radio>
          </b-form-radio-group>

          <p class="text-muted mt-sm-0 mt-2">{{ countOptionDescription }}</p>
          <p
            v-if="formData.countOption && formData.countOption !== 'E'"
            class="text-danger mt-sm-0 mt-2"
          >
            <b>AVISO:</b> Os <b>movimentos</b> dos <b>endereços</b> encontrados
            serão <b>BLOQUEADOS</b> até que o ajuste seja <b>finalizado</b> ou
            <b>cancelado</b>.
          </p>
          <p
            v-if="formData.countOption === 'W'"
            class="text-warning font-weight-bold mt-sm-0 mt-2"
          >
            AVISO: Pode demorar um pouco para o sistema copiar todos os estoques
            do armazém.
          </p>
        </div>

        <div v-if="formData.countOption === 'G'" class="col-12">
          <div v-if="loadingLocationGroupers" class="mb-5 pb-5">
            <animation />
          </div>

          <div
            v-else-if="formData.locationGroups && locationGroupers"
            class="row"
          >
            <div
              v-for="(grouper, index) in locationGroupers"
              :key="index"
              class="col-xl-2 col-sm-3 col-6"
            >
              <div v-if="locationGroupsSuggestions[grouper.id]" class="mb-3">
                <label>{{ grouper.name }}</label>
                <model-list-select
                  :list="locationGroupsSuggestions[grouper.id]"
                  option-value="id"
                  option-text="name"
                  v-model="selectedLocationGroups[grouper.id]"
                  placeholder="Digite para pesquisar..."
                  @searchchange="locationGroupSearchChanged($event, grouper)"
                  :isDisabled="!allowEdit || formData.movementStatus === 'C'"
                  :filterPredicate="alwaysTrueFilterPredicate"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            allowEdit && (!formData.countOption || formData.countOption !== 'E') && formData.movementStatus !== 'C'
          "
          class="col-12"
        >
          <div v-if="saving" class="col-auto">
            <animation />
          </div>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="openCountOptionConfirmationModal"
            :disabled="!isWarehouseSelected || !formData.countOption"
          >
            Confirmar
          </button>
        </div>

        <div
          v-if="formData.countOption && formData.countOption !== 'E'"
          class="col-12 my-3"
        >
          <div class="row">
            <div class="col-auto pr-0 ml-md-0 ml-3">
              <b-form-checkbox
                v-model="formData.applyAllItems"
                :disabled="!allowEdit"
              ></b-form-checkbox>
            </div>
            <div class="col pl-0">
              <label>Aplicar os movimentos a todos os itens do ajuste</label>
            </div>
          </div>
          <span class="text-muted">
            <span v-if="formData.applyAllItems"
              >O sistema aplicará os movimentos de <b>TODOS</b> os itens do
              ajuste. E nos itens em que o campo "<b>quantidade nova</b>"
              estiver <b>vazio</b> o sistema irá considerar <b>0</b> como a
              quantidade contada</span
            >
            <span v-else
              >O sistema <b>NÂO</b> aplicará os movimentos dos itens em que o
              campo "<b>quantidade nova</b>" estiver <b>vazio</b></span
            >
          </span>
        </div>
      </div>

      <div
        v-if="
          allowEdit &&
          (!formData.countOption || formData.countOption === 'E' || formData.id)
        "
        class="card"
      >
        <div
          v-if="formData.countOption && formData.countOption !== 'E'"
          class="card-header"
          @click="toggleShowItemForm"
          :class="{
            'cursor-pointer':
              formData.countOption && formData.countOption !== 'E',
          }"
        >
          Adicionar Novo Item
          <i
            :class="
              'fa fa-lg ' +
              (showItemForm ? 'fa-caret-down' : 'fa-caret-up') +
              ' float-right mt-1 mr-0'
            "
          ></i>
        </div>
        <div v-if="showItemForm" class="card-body">
          <b-row class="mx-md-n3 mx-1 align-items-end">
            <div class="col-xl-6 col-lg-11 col-md-11 col-sm-10 col-10">
              <label for="itemSelect">Selecione o Item:</label>
              <model-list-select
                id="itemSelect"
                :list="items"
                option-value="id"
                option-text="label"
                v-model="selectedItem"
                placeholder="Digite para pesquisar..."
                @searchchange="searchItem"
                :filterPredicate="alwaysTrueFilterPredicate"
              />
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 pl-0">
              <div v-if="searchItemInProcess">
                <animation />
              </div>
              <b-button
                v-else
                variant="secondary"
                @click.prevent="openItemBarcodeInput"
              >
                <i class="fa fa-barcode"></i>
              </b-button>
            </div>
          </b-row>

          <b-row class="mx-md-n3 mx-1 mt-3 align-items-end">
            <div class="col-sm-6 col-12">
              <div class="row align-items-end">
                <div class="col-sm-10 col-10">
                  <label for="locationSelect">Selecione o Endereço:</label>
                  <model-list-select
                    id="locationSelect"
                    :list="locations"
                    option-value="id"
                    option-text="name"
                    v-model="selectedLocation"
                    placeholder="Digite para pesquisar..."
                    @searchchange="searchLocation"
                    :isDisabled="!isWarehouseSelected"
                    :filterPredicate="alwaysTrueFilterPredicate"
                  />
                </div>
                <div class="col-sm-2 col-2 pl-0">
                  <div v-if="searchLocationInProcess">
                    <animation />
                  </div>
                  <b-button
                    v-else
                    variant="secondary"
                    @click.prevent="openLocationBarcodeInput"
                    :disabled="!isWarehouseSelected"
                  >
                    <i class="fa fa-barcode"></i>
                  </b-button>
                </div>
                <div class="col-10 mt-3">
                  <label for="lotSelect">
                    <small v-if="isItemAutomaticLot" class="text-danger">
                      O item selecionado é de lote automático
                    </small>
                    <span v-else>
                      Selecione o Lote:
                    </span>
                  </label>
                  <model-list-select
                    id="lotSelect"
                    :list="lots"
                    option-value="id"
                    option-text="lotNumber"
                    v-model="selectedLot"
                    placeholder="Digite para pesquisar..."
                    @searchchange="searchLot"
                    :filterPredicate="alwaysTrueFilterPredicate"
                    :isDisabled="isItemAutomaticLot"
                  />
                </div>
                <div class="col-2 pl-0">
                  <div v-if="searchLotInProcess">
                    <animation />
                  </div>
                  <b-button
                    v-else
                    variant="secondary"
                    @click.prevent="openLotBarcodeInput"
                    :disabled="isItemAutomaticLot"
                  >
                    <i class="fa fa-barcode"></i>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="row align-items-end">
                <div class="col-12 mb-3 pl-xl-3 pl-3 mt-sm-0 mt-3">
                  <label v-if="isUserManager">
                    <span>Quantidade Atual:</span><br />
                    <div v-if="loadingBalance">
                      <animation />
                    </div>
                    <span v-else-if="warehouseAndItemAndLocationAndLotSelected">
                      <composite-item-lot-quantity
                        :quantity="balanceQuantity"
                        :itemLot="selectedLot"
                      ></composite-item-lot-quantity>
                    </span>
                  </label>
                </div>

                <div class="col-12">
                  <b-form-group
                    label="Contagem:"
                    label-for="quantity"
                    class="mb-0"
                  >
                    <div class="row mr-0 align-items-center">
                      <div
                        v-if="isLotSelected && selectedLot.quantityPerVolume"
                        class="col"
                      >
                        <b-input
                          v-model="numberOfVolumes"
                          id="number-of-volumes"
                          class="margin-right"
                          onFocus="this.setSelectionRange(0, this.value.length)"
                        ></b-input>
                      </div>
                      <div
                        v-if="isLotSelected && selectedLot.quantityPerVolume"
                        class="col-auto px-0"
                      >
                        <span class="text-muted">x </span>
                        {{ selectedLot.quantityPerVolume }} =
                      </div>
                      <div class="col">
                        <b-form-input
                          v-model="quantity"
                          id="quantity"
                          class="margin-right"
                          onFocus="this.setSelectionRange(0, this.value.length)"
                        />
                      </div>
                      <div class="col-auto px-0">
                        <div v-if="saving">
                          <animation />
                        </div>
                        <b-button
                          v-else
                          variant="outline-primary"
                          @click.prevent="addItem"
                          :disabled="
                            !warehouseAndItemAndLocationAndLotSelected ||
                            loadingBalance ||
                            loadingStockAdjustmentItems
                          "
                        >
                          <i class="fa fa-check"></i>
                        </b-button>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </div>

      <div v-if="loadingStockAdjustmentItems" class="mb-5 pb-5">
        <animation />
      </div>

      <div
        v-if="
          (!formData.stockAdjustmentItems ||
            !formData.stockAdjustmentItems.length) &&
          !loadingStockAdjustmentItems
        "
        class="mb-3 mt-3"
      >
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            <span v-if="formData.countOption !== 'E'"
              >Nenhum estoque encontrado</span
            >
            <span v-else>Nenhum item adicionado</span>
          </div>
        </div>
      </div>

      <div
        v-if="
          formData.stockAdjustmentItems &&
          formData.stockAdjustmentItems.length &&
          !loadingStockAdjustmentItems
        "
        class="mb-3 mt-3"
      >
        <div
          v-if="
            formData.countOption && formData.countOption !== 'E' && allowEdit
          "
          class="row"
        >
          <div class="col-12">
            <div class="card">
              <div class="card-header">Buscar item pelos códigos de barras</div>
              <div class="card-body pb-2">
                <div class="row mx-md-n3 mx-0">
                  <div class="col-sm-6 col-12 mb-3">
                    <div class="row mx-0">
                      <div class="col-12">
                        <div
                          v-if="loadingStockAdjustmentItemLotBarcode"
                          class="mb-2 mx-5"
                        >
                          <animation />
                        </div>
                        <b-form-group
                          v-else
                          label="Código de barras do Lote/Item"
                          label-for="selectStockAdjustmentItemByBarcodeItemLotBarcodeInput"
                          class="mb-0"
                        >
                          <b-button
                            v-if="useCamera"
                            variant="secondary"
                            @click.prevent="openHeaderItemLotBarcodeInputModal()"
                          >
                            <i class="fa fa-barcode"></i>
                          </b-button>
                          <b-form-input
                            v-else
                            id="selectStockAdjustmentItemByBarcodeItemLotBarcodeInput"
                            v-model="barcodeStockAdjustmentItemItemLotBarcode"
                            @keyup.enter="readStockAdjustmentItemLotBarcode()"
                            :disabled="
                              saving ||
                              loadingStockAdjustmentItems ||
                              isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity
                            "
                            ref="selectStockAdjustmentItemByBarcodeItemLotBarcodeInput"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-12 mt-3">
                        <b-form-checkbox
                          v-model="incrementQuantity"
                          :disabled="
                            Boolean(
                              stockAdjustmentItemsFoundByBarcode &&
                                stockAdjustmentItemsFoundByBarcode.length
                            )
                          "
                        >
                          Incrementar contagem
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="
                          stockAdjustmentItemsFoundByBarcode &&
                          stockAdjustmentItemsFoundByBarcode.length
                        "
                        class="col-lg-auto col-sm-6 col-12 mt-3"
                      >
                        <button
                          type="button"
                          class="btn btn-secondary"
                          @click="cancelStockAdjustmentItemsFoundByBarcode"
                        >
                          <i class="fa fa-times"></i> Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="row mx-0">
                      <div class="col-12">
                        <div
                          v-if="loadingStockAdjustmentItemLocationBarcode"
                          class="mb-2 mx-5"
                        >
                          <animation />
                        </div>
                        <b-form-group
                          v-else
                          label="Código de barras do Endereço"
                          label-for="selectStockAdjustmentItemByBarcodeLocationBarcodeInput"
                          class="mb-0"
                        >
                          <b-button
                            v-if="useCamera"
                            variant="secondary"
                            :disabled="headerLocationBarcodeInputDisabled"
                            @click.prevent="openHeaderLocationBarcodeInputModal()"
                          >
                            <i class="fa fa-barcode"></i>
                          </b-button>
                          <b-form-input
                            v-else
                            id="selectStockAdjustmentItemByBarcodeLocationBarcodeInput"
                            v-model="barcodeStockAdjustmentItemLocationBarcode"
                            @keyup.enter="readStockAdjustmentItemLocationBarcode()"
                            :disabled="headerLocationBarcodeInputDisabled"
                            ref="selectStockAdjustmentItemByBarcodeLocationBarcodeInput"
                          />
                        </b-form-group>
                      </div>
                      <div
                        class="col-12 mt-3"
                        v-if="Boolean(previousLocation && previousLocation.id)"
                      >
                        <b-form-checkbox v-model="usePreviousLocation">
                          Usar endereço anterior
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <eel-stock-adjustment-items-table
                  class="mt-3"
                  ref="stockAdjustmentItemsFoundByBarcodeTable"
                  :formData="formData"
                  :stockAdjustmentItems="stockAdjustmentItemsFoundByBarcode"
                  :quantityChanged="
                    stockAdjustmentItemsFoundByBarcodeQuantityChanged
                  "
                  :setNumberOfVolumes="setNumberOfVolumes"
                  :isFoundByBarcode="true"
                  :saving="saving"
                  :loadingStockAdjustmentItems="loadingStockAdjustmentItems"
                  :saveStockAdjustmentItems="saveStockAdjustmentItems"
                  :showCurrentQuantity="isUserManager"
                  :allowEdit="allowEdit"
                  :incrementQuantity="incrementQuantity"
                  quantityFieldName="newQuantity"
                ></eel-stock-adjustment-items-table>
              </div>
            </div>
          </div>
        </div>

        <eel-stock-adjustment-items-table
          :formData="formData"
          :stockAdjustmentItems="formData.stockAdjustmentItems"
          :quantityChanged="quantityChanged"
          :setNumberOfVolumes="setNumberOfVolumes"
          :isFoundByBarcode="false"
          :saving="saving"
          :loadingStockAdjustmentItems="loadingStockAdjustmentItems"
          :stockAdjustmentItemsFoundByBarcode="
            stockAdjustmentItemsFoundByBarcode
          "
          :editIndex="editIndex"
          :editItem="editItem"
          :openDeleteModal="openDeleteModal"
          :showCurrentQuantity="isUserManager"
          :allowEdit="allowEdit"
          quantityFieldName="quantity"
        ></eel-stock-adjustment-items-table>

        <nav v-if="formData.countOption !== 'E'" class="mt-3">
          <div class="row justify-content-between">
            <div class="col-sm ml-md-0 ml-3 mb-sm-0 mb-3">
              <b-form-select
                v-model="stockAdjustmentItemsPerPage"
                :options="stockAdjustmentItemsPerPageOptions"
                style="width: 80px"
                @change="stockAdjustmentItemsPerPageChanged"
                :disabled="saving || loadingStockAdjustmentItems"
              ></b-form-select>
            </div>
            <div class="col-sm col-12">
              <b-pagination
                align="center"
                :limit="10"
                :total-rows="stockAdjustmentItemsTotalRows"
                :per-page="stockAdjustmentItemsPerPage"
                prev-text="Anterior"
                next-text="Próximo"
                number-of-page="stockAdjustmentItemsNumberOfPages"
                v-model="stockAdjustmentItemsCurrentPage"
                @change="stockAdjustmentItemsPageChanged"
                :disabled="saving"
              />
            </div>
            <div class="col"></div>
          </div>
        </nav>
      </div>

      <div class="row mx-md-n3 mx-1">
        <div class="col">
          <div class="row justify-content-between">
            <div class="col-auto">
              <button-back></button-back>
            </div>

            <div v-if="isUserManager && formData.id" class="col-auto pr-0">
              <b-button @click="$refs.balanceIncosistencyCheckModal.showModal()">
                Verificar inconsistências
              </b-button>
            </div>
          </div>
        </div>

        <div class="col-auto">
          <b-dropdown
            v-if="!saving && allowEdit"
            id="save-dropdown"
            text="Salvar"
            variant="primary"
            :disabled="!isWarehouseSelected || loadingStockAdjustmentItems"
          >
            <b-dropdown-item-button
              @click.prevent="save(null, $event)"
              :disabled="!isWarehouseSelected || loadingStockAdjustmentItems"
            >
              Salvar
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="allowEdit && formData.movementStatus === 'C'"
              @click="openReturnToPendingConfirmationModal"
            >
              Salvar e retornar para pendente
            </b-dropdown-item-button>
            <b-dropdown-item-button
              @click="confirmAdjustmentFinish"
              :disabled="
                !formData.id ||
                !isWarehouseSelected ||
                loadingStockAdjustmentItems
              "
            >
              {{
                formData.movementStatus === "L" && formData.countOption !== "E"
                  ? "Salvar e enviar para conferência"
                  : formData.movementStatus === "C" ||
                    !formData.countOption ||
                    formData.countOption === "E"
                  ? "Salvar e finalizar"
                  : ""
              }}
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div v-if="!saving && formData.movementStatus === 'O' && isWarehouseSelected" class="col-auto">
          <stock-adjustment-export-to-txt-button :stockAdjustment="formData"></stock-adjustment-export-to-txt-button>
        </div>
        <div v-if="saving" class="col-auto">
          <animation />
        </div>

      </div>
    </b-form>

    <confirmation-modal
      id="delete-confirmation-modal"
      msg="Tem certeza de que deseja remover esse item do ajuste?"
      :ok="deleteItem"
    ></confirmation-modal>

    <confirmation-modal
      id="finish-confirmation-modal"
      :msg="
        formData.movementStatus === 'L' && formData.countOption !== 'E'
          ? 'Tem certeza de que deseja enviar o ajuste para conferência?'
          : formData.movementStatus === 'C' ||
            !formData.countOption ||
            formData.countOption === 'E'
          ? 'Tem certeza de que deseja finalizar o ajuste? Após finalizado não é possível alterá-lo!'
          : ''
      "
      :ok="finishAdjustment"
    ></confirmation-modal>

    <confirmation-modal
      id="count-option-confirmation-modal"
      :msg="
        'Tem certeza de que deseja ' +
        countOptionDescription.charAt(0).toLowerCase() +
        countOptionDescription.slice(1) +
        '?' +
        (formData.stockAdjustmentItems && formData.stockAdjustmentItems.length
          ? ' AVISO: AS CONTAGENS DOS ITENS SERÃO PERDIDAS!'
          : '')
      "
      :ok="confirmCountOption"
    ></confirmation-modal>

    <barcode-input-modal
      id="location-barcode-input-modal"
      :attempts="locationBarcodeAttempts"
      :loading="loadingLocationBarcode"
      :ok="readLocationBarcode"
    ></barcode-input-modal>
    <barcode-input-modal
      id="lot-barcode-input-modal"
      :attempts="lotBarcodeAttempts"
      :loading="loadingLotBarcode"
      :ok="readLotBarcode"
    ></barcode-input-modal>
    <barcode-input-modal
      id="item-barcode-input-modal"
      :attempts="itemBarcodeAttempts"
      :loading="loadingItemBarcode"
      :ok="readItemBarcode"
    ></barcode-input-modal>
    <barcode-input-modal
      id="header-item-lot-barcode-input-modal"
      :loading="loadingStockAdjustmentItemLotBarcode"
      :ok="readStockAdjustmentItemLotBarcode"
      :config="config"
      :authUser="authUser"
      :parameter="parameter"
    ></barcode-input-modal>
    <barcode-input-modal
      id="header-location-barcode-input-modal"
      :loading="loadingStockAdjustmentItemLocationBarcode"
      :ok="readStockAdjustmentItemLocationBarcode"
      :config="config"
      :authUser="authUser"
      :parameter="parameter"
    ></barcode-input-modal>

    <order-attendance-fab
      ref="orderAttendanceFab"
      v-if="formData && formData.id && allowEdit"
      :stockAdjustmentId="formData.id"
      :startAttendanceAutomatically="true"
      type="AJUS"
    ></order-attendance-fab>
    <order-attendances-table-modal
      v-if="formData && formData.id"
      :title="'Apontamentos do pedido ' + (formData.number || formData.number)"
      id="order-attendances-modal"
      ref="orderAttendancesModal"
      :stockAdjustmentId="formData.id"
    ></order-attendances-table-modal>

    <confirmation-modal
      id="return-to-pending-confirmation-modal"
      msg="Tem certeza de que deseja retornar o ajuste para pendente?"
      :ok="returnToPending"
    ></confirmation-modal>

    <vue-confirm-dialog></vue-confirm-dialog>

    <b-modal
      size="md"
      centered
      lazy
      title="Lançamento duplicado"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showDuplicateItemModal"
      :hide-footer="true"
      id="duplicate-item-modal"
    >
      <b-container>
        <p class="mb-0">
          Esse item nesse endereço e desse lote já foi adicionado a lista
        </p>
        <p>Como deseja proceder?</p>

        <div v-if="saving" class="pt-5">
          <animation />
        </div>

        <div v-else-if="duplicateSAI" class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary w-100"
              @click="updateDuplicatedSAI(false)"
            >
              <i class="fa fa-plus"></i> Acrescentar {{ quantity }} à contagem
              do lançamento ({{ duplicateSAI.quantity || 0 }} +
              {{ quantity || 0 }} =
              {{ Number(duplicateSAI.quantity || 0) + Number(quantity || 0) }})
            </button>
          </div>
          <div class="col-12 mt-3">
            <button
              type="button"
              class="btn btn-warning w-100"
              @click="updateDuplicatedSAI(true)"
            >
              <i class="fa fa-retweet"></i> Substituir a contagem do lançamento
              ({{ duplicateSAI.quantity || 0 }}) pela nova contagem ({{
                quantity || 0
              }})
            </button>
          </div>
          <div class="col-12 mt-3">
            <button
              type="button"
              class="btn btn-secondary w-100"
              @click="showDuplicateItemModal = false"
            >
              <i class="fa fa-times"></i> Cancelar
            </button>
          </div>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      size="lg"
      centered
      lazy
      title="Detalhes do status"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showProcessStatusModal"
      :hide-footer="true"
      id="process-status-modal"
    >
      <b-container>
        <div v-if="formData.processStatusDescription && formData.processStatusDescription.length" class="mt-3">
          <span class="font-weight-bold">Descrição: </span>
          <p>{{formData.processStatusDescription}}</p>
        </div>
      </b-container>
    </b-modal>

    <order-attendance-warning
      v-if="formData && formData.id"
      :stockAdjustmentId="formData.id"
    ></order-attendance-warning>

    <balance-inconsistency-check-modal
      v-if="formData"
      :entity="formData"
      :updateBalances="updateInconsistentBalances"
      ref="balanceIncosistencyCheckModal"
    ></balance-inconsistency-check-modal>
  </div>
</template>

<script>
import CForm from "./c-form";
import ButtonBack from "./c-button-back";
import { ModelListSelect } from "vue-search-select";
import Animation from "@/components/loaders/animation";
import ConfirmationModal from "@/components/common/confirmation-modal";
import { httpClient } from "@/service";
import shared from "@/shared/shared";
import BarcodeInputModal from "@/components/common/barcode-input-modal";
import EelStockAdjustmentItemsTable from "@/components/tables/eel-stock-adjustment-items-table";
import OrderAttendanceFAB from "@/components/common/order-attendance-fab";
import OrderAttendancesTableModal from "@/components/tables/order-attendances-table-modal";
import CompositeItemLotQuantity from "@/components/common/composite-item-lot-quantity";
import StockAdjustmentProcessStatusBadge from "@/components/stock-adjustment-process-status-badge";
import firebase from "../../firebase";
import OrderAttendanceWarning from '@/components/common/order-attendance-warning';
import StockAdjustmentExportToTxtButton from '@/components/stock-adjustment-export-to-txt-button';
import BalanceIncosistencyCheckModal from '@/components/modals/balance-inconsistency-check-modal';

export default {
  name: "form-location-and-lot-adjustments",
  components: {
    Animation,
    CForm,
    ModelListSelect,
    ButtonBack,
    ConfirmationModal,
    BarcodeInputModal,
    EelStockAdjustmentItemsTable,
    "order-attendance-fab": OrderAttendanceFAB,
    OrderAttendancesTableModal,
    CompositeItemLotQuantity,
    StockAdjustmentProcessStatusBadge,
    OrderAttendanceWarning,
    StockAdjustmentExportToTxtButton,
    'balance-inconsistency-check-modal': BalanceIncosistencyCheckModal
  },

  data() {
    return {
      formData: {
        id: null,
        stockAdjustmentItems: [],
        locationGroups: [],
        numberOfItemsAdded: 0,
      },
      isValid: false,
      searchItemInProcess: false,
      items: [],
      selectedItem: {},
      itemSearchTimeout: null,
      searchLocationInProcess: false,
      locations: [],
      selectedLocation: {},
      locationSearchTimeout: null,
      searchLotInProcess: false,
      lots: [],
      selectedLot: {},
      lotSearchTimeout: null,
      quantity: "",
      orderItemsFields: [
        { key: "item.description", label: "Item" },
        { key: "quantity", label: "Quantidade" },
        { key: "actions", label: "Ações" },
      ],
      editIndex: null,
      deleteIndex: null,
      warehouses: [],
      selectedWarehouse: {},
      loadingWarehouses: false,
      saving: false,
      loadingBalance: false,
      balanceQuantity: null,
      loadingAdjusmtent: false,
      loadingStockAdjustmentItems: false,
      loadingLocationGroupers: false,
      locationGroupers: [],
      locationGroupsSuggestions: {},
      loadingLocationGroupSuggestions: {},
      locationGroupSuggestionSearchTimeout: null,
      reloadCountOptionDescriptionCounter: 0,
      selectedLocationGroups: {},
      stockAdjustmentItemsCurrentPage: 1,
      stockAdjustmentItemsTempPage: null,
      stockAdjustmentItemsPerPage: 10,
      stockAdjustmentItemsTotalRows: 10,
      loadingLocationBarcode: false,
      locationBarcodeAttempts: 0,
      loadingLotBarcode: false,
      lotBarcodeAttempts: 0,
      loadingItemBarcode: false,
      itemBarcodeAttempts: 0,
      stockAdjustmentItemsPerPageOptions: [5, 10, 20, 30, 50],
      loadingStockAdjustmentItemLotBarcode: false,
      barcodeStockAdjustmentItemItemLotBarcode: null,
      loadingStockAdjustmentItemLocationBarcode: false,
      barcodeStockAdjustmentItemLocationBarcode: null,
      stockAdjustmentItemsFoundByBarcode: [],
      isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity: false,
      saveStockAdjustmentAfterSavingItems: null,
      showItemForm: true,
      isUserManager: false,
      authUser: null,
      duplicateSAI: null,
      showDuplicateItemModal: false,
      incrementQuantity: true,
      numberOfVolumes: 1,
      previousLocation: {},
      usePreviousLocation: false,
      firebaseAdjustmentsDb: firebase.ref("stock-adjustments"),
      showProcessStatusModal: false,
      config: {},
      parameter: {}
    };
  },

  mounted: function () {
    this.config = shared.getConfig();
    this.authUser = shared.getLocalStorageObj("auth/user");
    this.parameter = shared.getParameterFromAuthUser(this.authUser);
    this.formData = {};
    this.setIsUserManager();
    this.findAdjustment();
    this.searchItem(null);
    this.getWarehouses();
  },

  computed: {
    isWarehouseSelected() {
      return (
        this.selectedWarehouse && Object.keys(this.selectedWarehouse).length
      );
    },

    isItemSelected() {
      return this.selectedItem && this.selectedItem.id;
    },

    isLocationSelected() {
      return this.selectedLocation && this.selectedLocation.id;
    },

    isLotSelected() {
      let value = this.selectedLot && this.selectedLot.id;

      if (value && this.selectedLot.quantityPerVolume) {
        this.quantity = this.selectedLot.quantityPerVolume;
      }

      return value;
    },

    isItemAutomaticLot() {
      return this.isItemSelected && this.selectedItem.automaticItemLot;
    },

    warehouseAndItemAndLocationAndLotSelected() {
      let value =
        this.isWarehouseSelected &&
        this.isItemSelected &&
        this.isLocationSelected &&
        (this.isLotSelected || this.isItemAutomaticLot);

      if (value && this.isUserManager) {
        this.getBalance();
      }

      return value;
    },

    countOptionDescription() {
      this.reloadCountOptionDescriptionCounter;
      if (this.formData && this.formData.countOption) {
        let description = "";

        if (this.formData.countOption === "E") {
          description = "Contar endereços específicos";
        } else {
          description = "Contar todos os endereços";

          if (
            this.formData.countOption === "G" &&
            this.selectedLocationGroups
          ) {
            let groupLabels = [];

            for (let grouperId in this.selectedLocationGroups) {
              if (
                this.selectedLocationGroups[grouperId].id &&
                this.selectedLocationGroups[grouperId].id
              ) {
                let grouper = this.locationGroupers.find(
                  (grouper) => grouper.id === grouperId
                );

                if (grouper) {
                  groupLabels.push(
                    grouper.name +
                      " " +
                      this.selectedLocationGroups[grouperId].name
                  );
                }
              }
            }

            if (groupLabels.length) {
              description += " dos Grupos ";

              for (let i = 0; i < groupLabels.length; i++) {
                description += groupLabels[i];

                if (i === groupLabels.length - 2) {
                  description += " e ";
                } else if (i < groupLabels.length - 2) {
                  description += ", ";
                }
              }
            }
          }

          if (this.selectedWarehouse && this.selectedWarehouse.id) {
            description += " do Armazém " + this.selectedWarehouse.name;
          }
        }

        return description;
      } else {
        return "";
      }
    },

    allowEdit() {
      return Boolean(
        this.formData &&
          (this.formData.movementStatus === "L" ||
            (this.formData.movementStatus === "C" && this.isUserManager))
      );
    },

    useCamera() {
      return shared.useCamera(this.parameter, this.config);
    },
    autoOpenCamera() {
      return shared.autoOpenCamera(this.parameter, this.config);
    },

    headerLocationBarcodeInputDisabled() {
      return !this.stockAdjustmentItemsFoundByBarcode ||
        !this.stockAdjustmentItemsFoundByBarcode.length ||
        this.saving ||
        this.loadingStockAdjustmentItems ||
        this.isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity ||
        (this.usePreviousLocation &&
          Boolean(this.previousLocation.barcode));
    }
  },

  methods: {
    async searchItem(searchText) {
      if (
        !this.searchItemInProcess &&
        ((searchText && searchText.length) ||
          !this.selectedItem ||
          Object.keys(this.selectedItem).length == 0)
      ) {
        if (this.itemSearchTimeout) {
          clearTimeout(this.itemSearchTimeout);
        }

        this.itemSearchTimeout = setTimeout(() => {
          this.searchItemInProcess = true;

          if (searchText && searchText.length) {
            httpClient
              .post(
                `${
                  process.env.VUE_APP_API_URL
                }items/condition?page=${0}&size=${10}`,
                {
                  conditions: [
                    {
                      logicalOperator: "OR",
                      conditions: [
                        {
                          field: "description",
                          conditionalOperator: "LIKE_START",
                          value: searchText,
                        },
                        {
                          field: "ref",
                          conditionalOperator: "LIKE_END",
                          value: shared.mountReduzidoFilter(searchText),
                        },
                      ],
                    },
                  ],
                }
              )
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false;
                if (error.message) {
                  this.$notify.error(error);
                }
              });
          } else {
            httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel();
              })
              .catch((error) => {
                this.searchItemInProcess = false;
                if (error.message) {
                  this.$notify.error(error);
                }
              });
          }
        }, 500);
      }
    },

    async searchLocation(searchText) {
      if (
        !this.searchLocationInProcess &&
        ((searchText && searchText.length) ||
          !this.selectedLocation ||
          Object.keys(this.selectedLocation).length == 0)
      ) {
        if (this.isWarehouseSelected) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          let conditions = [
            {
              field: "name",
              conditionalOperator: "LIKE_START",
              value: searchText,
            },
            {
              field: "stockLocale.id",
              value: this.selectedWarehouse.id,
            },
          ];

          if (this.isItemSelected) {
            conditions.push({
              logicalOperator: "OR",
              conditions: [
                {
                  field: "item.id",
                  value: this.selectedItem.id,
                  joinType: "LEFT",
                },
                {
                  field: "item.id",
                  conditionalOperator: "IS_NULL",
                  joinType: "LEFT",
                },
              ],
            });
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.searchLocationInProcess = true;

            httpClient
              .post(
                `${
                  process.env.VUE_APP_API_URL
                }locations/condition?page=${0}&size=${10}`,
                {
                  conditions: conditions,
                }
              )
              .then(({ data }) => {
                this.locations = this.handleLocationData(data);
              })
              .catch((error) => {
                this.searchLocationInProcess = false;
                if (error.message) {
                  this.$notify.error(error);
                }
              });
          }, 500);
        } else {
          this.locations = [];
        }
      }
    },

    async searchLot(searchText) {
      if (
        !this.searchLotInProcess &&
        ((searchText && searchText.length) ||
          !this.selectedLot ||
          Object.keys(this.selectedLot).length == 0)
      ) {
        if (this.lotSearchTimeout) {
          clearTimeout(this.lotSearchTimeout);
        }

        let conditions = [
          {
            field: "lotNumber",
            conditionalOperator: "LIKE_START",
            value: searchText,
          },
        ];

        if (this.isItemSelected) {
          conditions.push({
            field: "item.id",
            value: this.selectedItem.id,
          });
        }

        this.lotSearchTimeout = setTimeout(() => {
          this.searchLotInProcess = true;

          httpClient
            .post(
              `${
                process.env.VUE_APP_API_URL
              }item-lots/condition?page=${0}&size=${10}`,
              {
                conditions: conditions,
              }
            )
            .then(({ data }) => {
              this.lots = this.handleLotData(data);
            })
            .catch((error) => {
              this.searchLotInProcess = false;
              if (error.message) {
                this.$notify.error(error);
              }
            });
        }, 500);
      }
    },

    handleData(data) {
      this.searchItemInProcess = false;
      this.totalRows = data.data.totalElements;
      const items = data.data.content;

      return items;
    },

    handleLocationData(data) {
      this.searchLocationInProcess = false;
      this.totalRows = data.data.totalElements;
      const locations = data.data.content;
      locations.push({ id: null, name: "Nenhum" });

      return locations;
    },

    handleLotData(data) {
      this.searchLotInProcess = false;
      this.totalRows = data.data.totalElements;
      const lots = data.data.content;
      lots.push({ id: null, lotNumber: "Nenhum" });

      return lots;
    },

    addItem() {
      if (!this.quantity) {
        this.quantity = 0;
      }

      let index = this.formData.stockAdjustmentItems.length;
      let generatedType = null;

      if (this.selectedLot && this.selectedLot.item && this.selectedLot.item.id !== this.selectedItem.id) {
        this.$notify.textError(
          "O item selecionado não pertence ao lote selecionado"
        );
        return;
      }

      if (
        this.selectedLocation.type !== "U" &&
        this.selectedLocation.item &&
        this.selectedLocation.item.id !== this.selectedItem.id
      ) {
        this.$notify.textError(
          "O item selecionado não pertence ao endereço selecionado"
        );
        return;
      }

      if (this.editIndex != null) {
        index = this.editIndex;
      } else {
        this.duplicateSAI = this.formData.stockAdjustmentItems.find((sai) => {
          return (
            sai.item &&
            sai.itemLot &&
            sai.location &&
            sai.item.id === this.selectedItem.id &&
            (!this.selectedLot || !this.selectedLot.id || sai.itemLot.id === this.selectedLot.id) &&
            sai.location.id === this.selectedLocation.id
          );
        });

        if (this.duplicateSAI) {
          this.showDuplicateItemModal = true;
          return;
        }
      }

      if (this.formData.countOption === "G" && this.formData.locationGroups) {
        for (let i = 0; i < this.formData.locationGroups.length; i++) {
          if (
            !this.selectedLocation.locationGroups ||
            !this.selectedLocation.locationGroups.find(
              (g) => g.id === this.formData.locationGroups[i].id
            )
          ) {
            this.$notify.textError(
              "Esse endereço não pertence aos grupos selecionados"
            );
            return;
          }
        }
      }

      if (this.quantity > this.balanceQuantity) {
        generatedType = "ENT";
      } else if (this.balanceQuantity > this.quantity) {
        generatedType = "SAI";
      }

      let stockAdjustmentItem = {
        quantity: Number(this.quantity.toString().replace(",", ".")),
        numberOfVolumes: Number(
          this.numberOfVolumes.toString().replace(",", ".")
        ),
        currentQuantity: this.balanceQuantity,
        generatedType: generatedType,
        item: this.selectedItem,
        location: this.selectedLocation,
        itemLot: this.selectedLot,
      };

      if (stockAdjustmentItem.itemLot && !stockAdjustmentItem.itemLot.id) {
        stockAdjustmentItem.itemLot = null;
      }

      if (!this.formData.countOption || this.formData.countOption === "E") {
        this.formData.stockAdjustmentItems[index] = stockAdjustmentItem;
        this.handleStockAdjustmentItemSaved();
      } else {
        stockAdjustmentItem.stockAdjustment = {
          id: this.formData.id,
          companyGroup: this.formData.companyGroup,
        };
        stockAdjustmentItem.searchCurrentQuantity = true;
        this.saving = true;

        let conditions = [
          {
            field: "stockAdjustment.id",
            value: stockAdjustmentItem.stockAdjustment.id,
          },
          {
            field: "item.id",
            value: stockAdjustmentItem.item.id,
          },
          {
            field: "location.id",
            value: stockAdjustmentItem.location.id,
          },
        ];

        if (stockAdjustmentItem.itemLot && stockAdjustmentItem.itemLot.id) {
          conditions.push({
            field: "itemLot.id",
            value: stockAdjustmentItem.itemLot.id,
          });
        }

        httpClient
          .post(
            `${process.env.VUE_APP_API_URL}stock-adjustment-items/condition?page=0&size=1`, {
              conditions: conditions,
            }
          )
          .then((data) => {
            if (data.data.data.content && data.data.data.content.length) {
              this.saving = false;
              this.duplicateSAI = this.formData.stockAdjustmentItems.find(
                (sai) => sai.id === data.data.data.content[0].id
              );

              if (!this.duplicateSAI) {
                this.duplicateSAI = data.data.data.content[0];
              }

              this.showDuplicateItemModal = true;
            } else {
              httpClient
              .post(
                `${process.env.VUE_APP_API_URL}stock-adjustment-items`,
                stockAdjustmentItem
              )
              .then((data) => {
                this.saving = false;
                this.$notify.success("Item adicionado com sucesso");
                if (
                  data.data.data.quantity &&
                  data.data.data.itemLot &&
                  data.data.data.itemLot.quantityPerVolume
                ) {
                  data.data.data.numberOfVolumes = Math.floor(
                    shared.divide(
                      data.data.data.quantity,
                      data.data.data.itemLot.quantityPerVolume
                    )
                  );
                }

                this.formData.stockAdjustmentItems.unshift(data.data.data);
                this.handleStockAdjustmentItemSaved();
              })
              .catch((error) => {
                this.saving = false;
                if (error.message) {
                  this.$notify.error(error);
                } else {
                  this.$notify.textError("Houve um erro ao salvar o item");
                }
              });
            }
          })
          .catch((error) => {
            this.saving = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao salvar o item");
            }
          });
      }
    },

    handleStockAdjustmentItemSaved() {
      this.selectedItem = {};
      this.selectedLocation = {};
      this.selectedLot = {};
      this.showItemForm = false;
      this.quantity = "";
      this.numberOfVolumes = 1;
      this.editIndex = null;
      let formData = this.formData;
      this.formData = {};

      this.$nextTick(() => {
        this.formData = formData;
        this.showItemForm = true;
      });
    },

    editItem(index) {
      this.editIndex = index;
      let sai = this.formData.stockAdjustmentItems[index];
      this.quantity = sai.quantity;

      this.setItemLabel(sai.item);

      if (!this.items.find(i => i.id === sai.item.id)) {
        this.items.unshift(sai.item);
      }

      this.selectedItem = sai.item;

      if (!this.locations.find(i => i.id === sai.location.id)) {
        this.locations.unshift(sai.location);
      }

      this.selectedLocation = sai.location;

      if (sai.itemLot && sai.itemLot.id) {
        if (!this.lots.find(i => i.id === sai.itemLot.id)) {
          this.lots.unshift(sai.itemLot);
        }

        this.selectedLot = sai.itemLot;
      } else {
        this.selectedLot = {};
      }
    },

    openDeleteModal(index) {
      this.deleteIndex = index;
      this.$bvModal.show("delete-confirmation-modal");
    },

    deleteItem() {
      if (this.formData.stockAdjustmentItems[this.deleteIndex].id) {
        this.loadingStockAdjustmentItems = true;
        httpClient
          .delete(
            `${process.env.VUE_APP_API_URL}stock-adjustment-items`,
            this.formData.stockAdjustmentItems[this.deleteIndex].id
          )
          .then((data) => {
            this.loadingStockAdjustmentItems = false;
            this.handleItemDeleted();
          })
          .catch((error) => {
            this.loadingStockAdjustmentItems = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao excluír o item");
            }
          });
      } else {
        this.handleItemDeleted();
      }
    },

    handleItemDeleted() {
      this.formData.stockAdjustmentItems.splice(this.deleteIndex, 1);
      this.$notify.success("Item excluído com sucesso");
      this.deleteIndex = null;

      let formData = this.formData;
      this.formData = {};

      this.$nextTick(() => {
        this.formData = formData;
      });
    },

    save(options, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.formData && this.formData.stockAdjustmentItems) {
        this.formData.stockAdjustmentItems.forEach((sai) => {
          if (sai.quantity) {
            sai.quantity = Number(sai.quantity.toString().replace(",", "."));
          }
        });
      }

      if (
        !this.saveStockAdjustmentAfterSavingItems &&
        (!options || !options.replaceSAI) &&
        this.formData.stockAdjustmentItems &&
        this.formData.stockAdjustmentItems.length &&
        (!this.formData.countOption || this.formData.countOption !== "E")
      ) {
        this.saveStockAdjustmentAfterSavingItems = options;
        this.saveChangedStockAdjustmentItems();
        return;
      }

      if (
        (!this.formData.countOption || this.formData.countOption === "E") &&
        (!this.formData ||
          !this.formData.stockAdjustmentItems ||
          !this.formData.stockAdjustmentItems.length)
      ) {
        this.$notify.textError("Por favor inclua ao menos um item");
        return;
      }

      this.formData.locationGroups = [];

      if (this.formData.countOption === "G") {
        Object.values(this.selectedLocationGroups).forEach((group) => {
          if (group && group.id) {
            this.formData.locationGroups.push(group);
          }
        });

        let atLeastOneGroup = false;

        for (let i = 0; i < this.formData.locationGroups.length; i++) {
          if (
            this.formData.locationGroups[i].name &&
            this.formData.locationGroups[i].name.length
          ) {
            atLeastOneGroup = true;
            break;
          }
        }

        if (!atLeastOneGroup) {
          this.$notify.textError("Por favor informe ao menos um grupo");
          return;
        }
      }

      this.formData.stockLocale = this.selectedWarehouse;
      if (this.selectedWarehouse) {
        this.formData.companyGroup = this.selectedWarehouse.companyGroup;
      }

      if (!this.formData.ref || !this.formData.ref.length) {
        this.formData.ref = "ADJUSTMENT_" + new Date().getTime();
      }

      if (!this.formData.departureDate) {
        this.formData.departureDate = new Date();
      }

      var successMessage = "Ajuste salvo com sucesso";
      if (options && options.finish) {
        successMessage = "Ajuste finalizado com sucesso";
      }

      //let patchObj = {
        /*locationGroups: this.formData.locationGroups,
        stockLocale: this.formData.stockLocale,
        ref: this.formData.ref,
        departureDate: this.formData.departureDate,*/
        //applyAllItems: this.formData.applyAllItems,
        //countOption: this.formData.countOption
      //};

      let created = false;
      let promise;
      if (options && options.finish) {
        if (
          this.formData.countOption &&
          this.formData.countOption !== "E" &&
          this.formData.movementStatus === "L"
        ) {
          promise = httpClient.patch(
            `${process.env.VUE_APP_API_URL}stock-adjustments/${this.formData.id}`,
            { movementStatus: "C", applyAllItems: this.formData.applyAllItems }
          );
        } else {
          promise = httpClient.post(
            `${process.env.VUE_APP_API_URL}stock-adjustments/finish`,
            this.formData
          );
        }
      } else if (options && options.returnToPending) {
        promise = httpClient.patch(
          `${process.env.VUE_APP_API_URL}stock-adjustments/${this.formData.id}`,
          { movementStatus: "L", applyAllItems: this.formData.applyAllItems }
        );
      } else if (this.formData.id) {
        promise = httpClient.put(
          `${process.env.VUE_APP_API_URL}stock-adjustments/`,
          this.formData
        );
      } else {
        promise = httpClient.post(
          `${process.env.VUE_APP_API_URL}stock-adjustments/`,
          this.formData
        );
        created = true;
      }

      if (promise) {
        this.saving = true;
        promise
          .then((data) => {
            if (created) {
              this.saving = false;
              this.$notify.success(successMessage);

              this.$router.push(
                "/orders/location-and-lot-item-adjustment/edit/" +
                  data.data.data.id
              );
              this.formData.id = data.data.data.id;

              if (
                this.formData.countOption &&
                this.formData.countOption !== "E"
              ) {
                this.formData.createStockAdjustmentItems = false;
              }

              this.getStockAdjustmentItems();
            } else {
              this.saving = false;
              this.$notify.success(successMessage);

              this.saveStockAdjustmentAfterSavingItems = null;
              let stockAdjustmentItems = this.formData.stockAdjustmentItems;
              let createStockAdjustmentItems = this.formData
                .createStockAdjustmentItems;
              this.formData = data.data.data;
              if (
                (!options || !options.leavePage) &&
                createStockAdjustmentItems
              ) {
                this.getStockAdjustmentItems();
              } else {
                this.formData.stockAdjustmentItems = stockAdjustmentItems;
              }
              this.formData.createStockAdjustmentItems = false;
              this.setAdjustmentValues();
              if (options && options.leavePage) {
                this.$router.push("/orders/item-adjustment/all");
              }
              //this.$root.$emit("reloadOrdersCounts");
              this.reloadFormData();
            }
          })
          .catch((error) => {
            this.saving = false;
            this.formData.createStockAdjustmentItems = false;
            this.saveStockAdjustmentAfterSavingItems = null;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao salvar");
            }
          });
      }
    },

    getWarehouses() {
      this.loadingWarehouses = true;
      httpClient
        .post(
          `${
            process.env.VUE_APP_API_URL
          }stock-locales/condition?page=${0}&size=${100}`,
          {
            conditions: [
              {
                field: "active",
                value: true,
              },
            ],
          }
        )
        .then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    setItemsLabel() {
      if (this.items && this.items.length) {
        this.items.forEach((item) => {
          this.setItemLabel(item);
        });

        this.items.push({ id: null, label: "Nenhum" });
      }
    },

    setItemLabel(item) {
      item.label =
        this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
    },

    alwaysTrueFilterPredicate() {
      return true;
    },

    getBalance() {
      this.loadingBalance = true;

      if (this.isItemAutomaticLot) {
        httpClient.get(`${process.env.VUE_APP_API_URL}locations/quantity/${this.selectedLocation.id}/item/${this.selectedItem.id}`)
        .then((data) => {
          this.loadingBalance = false;
          if (data.data.data) {
            this.balanceQuantity = data.data.data;
          } else {
            this.balanceQuantity = 0;
          }
        })
        .catch((error) => {
          this.loadingBalance = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(
              "Houve um erro ao buscar a quantidade atual do item"
            );
          }
        });
      } else {
        httpClient.post(
          `${
            process.env.VUE_APP_API_URL
          }balances/condition?page=${0}&size=${1}`,
          {
            conditions: [
              {
                field: "itemLot.id",
                value: this.selectedLot.id,
              },
              {
                field: "location.id",
                value: this.selectedLocation.id,
              },
            ],
          }
        )
        .then((data) => {
          this.loadingBalance = false;
          if (
            data.data.data &&
            data.data.data.content &&
            data.data.data.content.length
          ) {
            this.balanceQuantity =
              (data.data.data.content[0].entries || 0) -
              (data.data.data.content[0].exits || 0);
          } else {
            this.balanceQuantity = 0;
          }
        })
        .catch((error) => {
          this.loadingBalance = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError(
              "Houve um erro ao buscar a quantidade atual do item"
            );
          }
        });
      }
    },

    findAdjustment() {
      if (this.$route.params.id) {
        this.loadingAdjusmtent = true;
        httpClient
          .get(
            `${process.env.VUE_APP_API_URL}stock-adjustments/${this.$route.params.id}`
          )
          .then((data) => {
            this.loadingAdjusmtent = false;
            this.formData = data.data.data;
            this.firebaseAdjustmentsDb
              .child(this.authUser.companyGroup.alias)
              .child(this.formData.id)
              .on("value", this.updateFormData);

            this.setAdjustmentValues();
            this.getStockAdjustmentItems();
            this.setIsUserManager();

            this.$nextTick(() => {
              if (this.$refs && this.$refs.orderAttendanceFab) {
                this.$refs.orderAttendanceFab.startAutomaticAttendance();
              }
            });
          })
          .catch((error) => {
            this.loadingAdjusmtent = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao buscar o ajuste");
            }
          });
      } else {
        this.formData.movementStatus = "L";
        this.formData.type = "EEL";
        this.setAdjustmentValues();
      }
    },

    updateFormData(item) {
      let itemValue = item.val();
      if (itemValue) {
        this.formData = { ...this.formData, ...itemValue };
      }
    },

    setAdjustmentValues() {
      this.selectedItem = this.formData.item || {};

      if (!this.formData.stockAdjustmentItems) {
        this.formData.stockAdjustmentItems = [];
      }

      if (!this.formData.stockLocale) {
        let locaStorageWarehouse = shared.getLocalStorageObj("order/warehouse");

        if (locaStorageWarehouse && locaStorageWarehouse.id) {
          this.formData.stockLocale = locaStorageWarehouse;
        }
      }

      if (this.formData.stockLocale) {
        if (!this.warehouses) {
          this.warehouses = [];
        }

        if (
          !this.warehouses.find((w) => w.id === this.formData.stockLocale.id)
        ) {
          this.warehouses.push(this.formData.stockLocale);
        }

        this.selectedWarehouse = this.formData.stockLocale;
      }

      if (!this.formData.id && !this.formData.countOption) {
        this.formData.countOption = "E";
      }

      if (this.formData.countOption && this.formData.countOption !== "E") {
        this.showItemForm = false;
      }

      if (!this.locationGroupers || !this.locationGroupers.length) {
        this.getLocationGroupers();
      }

      if (
        this.formData.generatedFromOrder &&
        this.formData.generatedFromOrder.fiscalOperation
      ) {
        this.formData.generatedFromOrder.fiscalOperation.showType = shared.setOrderShowType(
          this.formData.generatedFromOrder
        );
      }
    },

    getStockAdjustmentItems() {
      if (this.formData && this.formData.id) {
        if (!this.formData.countOption || this.formData.countOption === "E") {
          this.stockAdjustmentItemsCurrentPage = 1;
          this.stockAdjustmentItemsPerPage = 2000;
        }

        let sort = "";

        if (this.formData.countOption && this.formData.countOption !== "E") {
          sort = "&sort=countStatus,asc&sort=createdAt,desc";
        }

        this.loadingStockAdjustmentItems = true;
        this.formData.stockAdjustmentItems = [];
        httpClient
          .post(
            `${
              process.env.VUE_APP_API_URL
            }stock-adjustment-items/condition?page=${
              this.stockAdjustmentItemsCurrentPage - 1
            }&size=${this.stockAdjustmentItemsPerPage}${sort}`,
            {
              conditions: [
                {
                  field: "stockAdjustment.id",
                  value: this.formData.id,
                },
              ],
            }
          )
          .then(({ data }) => {
            this.loadingStockAdjustmentItems = false;
            this.stockAdjustmentItemsTotalRows = data.data.totalElements;
            this.formData.stockAdjustmentItems = data.data.content;

            this.formData.stockAdjustmentItems.forEach((sai) => {
              if (
                sai.quantity &&
                sai.itemLot &&
                sai.itemLot.quantityPerVolume
              ) {
                sai.numberOfVolumes = Math.floor(
                  shared.divide(sai.quantity, sai.itemLot.quantityPerVolume)
                );
              }
            });
          })
          .catch((error) => {
            this.loadingStockAdjustmentItems = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError(
                "Houve um erro ao buscar os itens do pedido"
              );
            }
          });
      }
    },

    confirmAdjustmentFinish() {
      this.$bvModal.show("finish-confirmation-modal");
    },

    finishAdjustment() {
      this.save({
        finish: true,
        leavePage: true,
      });
    },

    reloadFormData(propertiesToBeUpdated) {
      if (propertiesToBeUpdated) {
        for (let key in propertiesToBeUpdated) {
          this.formData[key] = propertiesToBeUpdated[key];
        }

        this.setIsUserManager();
      }

      let formData = this.formData;
      this.formData = {};

      this.$nextTick(() => {
        this.formData = formData;
      });
    },

    getLocationGroupers() {
      this.loadingLocationGroupers = true;
      httpClient
        .get(`${process.env.VUE_APP_API_URL}location-groupers?page=0&size=100`)
        .then((data) => {
          this.loadingLocationGroupers = false;
          this.locationGroupers = data.data.data.content;
          this.addMissingLocationGroups();
        })
        .catch((error) => {
          this.loadingLocationGroupers = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError("Houve um erro ao buscar os agrupadores");
          }
        });
    },

    addMissingLocationGroups() {
      this.locationGroupers = shared.sortLocationGroupers(
        this.locationGroupers
      );
      this.locationGroupsSuggestions = {};
      this.loadingLocationGroupSuggestions = {};
      this.selectedLocationGroups = {};

      if (!this.formData.locationGroups) {
        this.formData.locationGroups = [];
      }

      this.locationGroupers.forEach((grouper) => {
        this.locationGroupsSuggestions[grouper.id] = [];
        this.loadingLocationGroupSuggestions[grouper.id] = false;

        if (!this.formData.locationGroups) {
          this.formData.locationGroups = [];
        }

        let currentLocationGroup = this.formData.locationGroups.find(
          (g) => g.locationGrouper && g.locationGrouper.id === grouper.id
        );
        if (currentLocationGroup) {
          this.selectedLocationGroups[grouper.id] = currentLocationGroup;
          this.locationGroupsSuggestions[grouper.id].push(currentLocationGroup);
        } else {
          this.selectedLocationGroups[grouper.id] = {
            locationGrouper: grouper,
          };
        }
      });

      this.formData.locationGroups = shared.sortLocationGroups(
        this.formData.locationGroups,
        this.locationGroupers
      );
    },

    locationGroupSearchChanged(searchText, grouper) {
      // Não tirar essa porra de linha daqui pq se nao a bosta do componente buga
      this.reloadCountOptionDescriptionCounter++;

      if (
        !this.loadingLocationGroupSuggestions[grouper.id] &&
        searchText &&
        searchText.length
      ) {
        if (this.locationGroupSuggestionSearchTimeout) {
          clearTimeout(this.locationGroupSuggestionSearchTimeout);
        }

        this.locationGroupSuggestionSearchTimeout = setTimeout(() => {
          this.loadingLocationGroupSuggestions[grouper.id] = true;
          httpClient
            .post(
              `${process.env.VUE_APP_API_URL}location-groups/select-and-where?page=0&size=10`,
              {
                select: ["id", "name"],
                where: {
                  conditions: [
                    {
                      field: "locationGrouper.id",
                      value: grouper.id,
                    },
                    {
                      field: "name",
                      conditionalOperator: "LIKE_START",
                      value: searchText,
                    },
                  ],
                },
              }
            )
            .then(({ data }) => {
              this.loadingLocationGroupSuggestions[grouper.id] = false;
              this.locationGroupsSuggestions[grouper.id] = data.data.content;
              this.locationGroupsSuggestions[grouper.id].unshift({
                id: null,
                name: "Nenhum(a)",
              });

              let locationGroupsSuggestions = this.locationGroupsSuggestions;
              this.locationGroupsSuggestions = {};
              this.locationGroupsSuggestions = locationGroupsSuggestions;
            })
            .catch((error) => {
              this.loadingLocationGroupSuggestions[grouper.id] = false;
              if (error.message) {
                this.$notify.error(error);
              }
              this.locationGroupsSuggestions[grouper.id] = [];
            });
        }, 200);
      }
    },

    openCountOptionConfirmationModal() {
      this.reloadCountOptionDescriptionCounter++;
      this.$bvModal.show("count-option-confirmation-modal");
    },

    confirmCountOption() {
      this.formData.createStockAdjustmentItems = true;
      this.setIsUserManager();
      this.save({
        replaceSAI: true,
      });
    },

    stockAdjustmentItemsPageChanged(page, force) {
      if (!force && this.isThereAnyChangedStockAdjustmentItem()) {
        this.stockAdjustmentItemsTempPage = page;

        this.$confirm({
          message: `Você possui itens alterados não salvos, deseja salvá-los antes de mudar de página?`,
          button: { no: "Não", yes: "Sim" },
          callback: (confirm) => {
            if (confirm) {
              this.confirmStockAdjustmentItemsSave();
            } else {
              this.changeStockAdjustmentItemsPageWithoutChecking();
            }
          },
        });
      } else {
        this.stockAdjustmentItemsCurrentPage = page;
        this.getStockAdjustmentItems();
      }
    },

    openLocationBarcodeInput() {
      this.$bvModal.show("location-barcode-input-modal");
    },

    readLocationBarcode(barcode) {
      if (!this.loadingLocationBarcode) {
        if (barcode && barcode.length) {
          this.loadingLocationBarcode = true;
          httpClient
            .get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then((data) => {
              this.loadingLocationBarcode = false;

              if (data.data.data) {
                if (
                  this.isItemSelected &&
                  data.data.data.item &&
                  data.data.data.item.id !== this.selectedItem.id
                ) {
                  this.$notify.textError(
                    "Endereço não é compatível com este item"
                  );
                } else if (
                  this.selectedWarehouse &&
                  data.data.data.stockLocale.id !== this.selectedWarehouse.id
                ) {
                  this.$notify.textError(
                    "Endereço não é compatível com este armazém"
                  );
                } else {
                  this.locations = [data.data.data];
                  this.selectedLocation = data.data.data;
                  this.$bvModal.hide("location-barcode-input-modal");
                  this.$notify.success("Endereço encontrado");
                }
              } else {
                this.$notify.textError("Endereço não encontrado");
              }

              this.locationBarcodeAttempts++;
            })
            .catch((error) => {
              this.loadingLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                );
              }

              this.locationBarcodeAttempts++;
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }

      this.locationBarcodeAttempts++;
    },

    openLotBarcodeInput() {
      this.$bvModal.show("lot-barcode-input-modal");
    },

    readLotBarcode(barcode) {
      if (!this.loadingLotBarcode) {
        if (barcode && barcode.length) {
          this.loadingLotBarcode = true;
          httpClient
            .get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then((data) => {
              this.loadingLotBarcode = false;

              if (data.data.data) {
                if (
                  this.isItemSelected &&
                  data.data.data.item.id !== this.selectedItem.id
                ) {
                  this.$notify.textError("Lote não é compatível com este item");
                } else {
                  this.lots = [data.data.data];
                  this.selectedLot = data.data.data;
                  this.$bvModal.hide("lot-barcode-input-modal");
                  this.$notify.success("Lote encontrado");
                }
              } else {
                this.$notify.textError("Lote não encontrado");
              }

              this.lotBarcodeAttempts++;
            })
            .catch((error) => {
              this.loadingLotBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente"
                );
              }

              this.lotBarcodeAttempts++;
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }

      this.lotBarcodeAttempts++;
    },

    openItemBarcodeInput() {
      this.$bvModal.show("item-barcode-input-modal");
    },

    readItemBarcode(barcode) {
      if (!this.loadingItemBarcode) {
        if (barcode && barcode.length) {
          this.loadingItemBarcode = true;
          httpClient
            .post(`${process.env.VUE_APP_API_URL}items/condition?page=0&size=1`, {
              field: 'gtin', value: barcode
            }).then((data) => {
              this.loadingItemBarcode = false;

              if (data.data.data.content.length) {
                this.items = data.data.data.content;
                this.setItemsLabel();
                this.selectedItem = this.items[0];
                this.$bvModal.hide("item-barcode-input-modal");
                this.$notify.success("Item encontrado");
              } else {
                this.$notify.textError("Item não encontrado");
              }

              this.itemBarcodeAttempts++;
            })
            .catch((error) => {
              this.loadingItemBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o item pelo código de barras. Por favor tente novamente"
                );
              }

              this.itemBarcodeAttempts++;
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }

      this.itemBarcodeAttempts++;
    },

    quantityChanged(index) {
      this.formData.stockAdjustmentItems[index].quantityChanged = true;

      if (
        this.formData.stockAdjustmentItems[index].quantity >
        this.formData.stockAdjustmentItems[index].currentQuantity
      ) {
        this.formData.stockAdjustmentItems[index].generatedType = "ENT";
      } else if (
        this.formData.stockAdjustmentItems[index].currentQuantity >
        this.formData.stockAdjustmentItems[index].quantity
      ) {
        this.formData.stockAdjustmentItems[index].generatedType = "SAI";
      }

      this.reloadFormData();
    },

    setNumberOfVolumes(index, isFoundByBarcode, quantityFieldName) {
      let sais = isFoundByBarcode
        ? this.stockAdjustmentItemsFoundByBarcode
        : this.formData.stockAdjustmentItems;

      if (
        sais[index].itemLot &&
        sais[index].itemLot.quantityPerVolume &&
        sais[index].numberOfVolumes
      ) {
        sais[index][quantityFieldName] =
          sais[index].itemLot.quantityPerVolume * sais[index].numberOfVolumes;

        if (isFoundByBarcode) {
          this.stockAdjustmentItemsFoundByBarcode = [];

          this.$nextTick(() => {
            this.stockAdjustmentItemsFoundByBarcode = sais;
          });
        } else {
          this.formData.stockAdjustmentItems = sais;
          this.quantityChanged(index);
        }
      }
    },

    isThereAnyChangedStockAdjustmentItem() {
      return (
        this.formData.stockAdjustmentItems &&
        this.formData.stockAdjustmentItems.find((sai) => sai.quantityChanged)
      );
    },

    confirmStockAdjustmentItemsSave() {
      this.save();
    },

    saveChangedStockAdjustmentItems() {
      let changedStockAdjustmentItems = this.formData.stockAdjustmentItems.filter(
        (sai) => sai.quantityChanged
      );

      if (changedStockAdjustmentItems.length) {
        this.saveStockAdjustmentItems(changedStockAdjustmentItems, false);
      } else if (this.saveStockAdjustmentAfterSavingItems) {
        this.save(this.saveStockAdjustmentAfterSavingItems);
      } else {
        this.$notify.warn("Nenhum item foi alterado");
      }
    },

    saveStockAdjustmentItems(stockAdjustmentItems, foundByBarcode) {
      if (this.formData.createStockAdjustmentItems) {
        if (this.saveStockAdjustmentAfterSavingItems) {
          this.save(this.saveStockAdjustmentAfterSavingItems);
        }

        return;
      }

      if (stockAdjustmentItems && stockAdjustmentItems.length) {
        stockAdjustmentItems.forEach((sai) => {
          if (sai.quantity === '') {
            sai.quantity = null;
          }

          if (sai.newQuantity === '') {
            sai.newQuantity = null;
          }
        });

        if (foundByBarcode) {
          stockAdjustmentItems.forEach((sai) => {
            if (this.incrementQuantity) {
              if (sai.newQuantity) {
                if (!sai.quantity) {
                  sai.quantity = 0;
                }

                sai.quantity = Number(sai.quantity) + Number(sai.newQuantity);
              }
            } else {
              sai.quantity = sai.newQuantity;
            }
          });
        }

        this.saving = true;
        httpClient
          .put(
            `${process.env.VUE_APP_API_URL}stock-adjustment-items/multiple?patch=true`,
            stockAdjustmentItems.map((sai) => {
              return {
                id: sai.id,
                quantity: sai.quantity,
              };
            })
          )
          .then((data) => {
            this.saving = false;

            if (data.data.data.length >= stockAdjustmentItems.length) {
              if (stockAdjustmentItems.length === 1) {
                this.$notify.success("Item salvo com sucesso");
              } else {
                this.$notify.success("Itens salvos com sucesso");
              }

              if (foundByBarcode) {
                data.data.data.forEach((savedSAI) => {
                  let index = this.stockAdjustmentItemsFoundByBarcode.findIndex(
                    (s) => s.id === savedSAI.id
                  );

                  if (index > -1) {
                    this.stockAdjustmentItemsFoundByBarcode[
                      index
                    ].quantityChanged = false;
                  }

                  index = this.formData.stockAdjustmentItems.findIndex(
                    (s) => s.id === savedSAI.id
                  );

                  if (index > -1) {
                    this.formData.stockAdjustmentItems[
                      index
                    ].quantityChanged = false;
                    this.formData.stockAdjustmentItems[index].quantity =
                      savedSAI.quantity;
                    if (
                      this.formData.stockAdjustmentItems[index].itemLot &&
                      this.formData.stockAdjustmentItems[index].itemLot
                        .quantityPerVolume
                    ) {
                      this.formData.stockAdjustmentItems[
                        index
                      ].numberOfVolumes = Math.floor(
                        shared.divide(
                          this.formData.stockAdjustmentItems[index].quantity,
                          this.formData.stockAdjustmentItems[index].itemLot
                            .quantityPerVolume
                        )
                      );
                    }
                  }
                });

                if (
                  !this.stockAdjustmentItemsFoundByBarcode.find(
                    (sai) => sai.quantityChanged
                  )
                ) {
                  this.isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity = false;
                  this.focusOnStockAdjustmentItemItemLotBarcodeInput();
                }
              } else {
                this.formData.stockAdjustmentItems.forEach((sai) => {
                  sai.quantityChanged = false;
                });

                if (this.stockAdjustmentItemsTempPage) {
                  this.changeStockAdjustmentItemsPageWithoutChecking();
                }
              }

              if (this.saveStockAdjustmentAfterSavingItems) {
                this.save(this.saveStockAdjustmentAfterSavingItems);
              }
            } else {
              this.$notify.textError(
                "Não foi possível salvar todos os itens. Houve um erro ao salvar alguns deles."
              );
            }
          })
          .catch((error) => {
            this.saving = false;
            this.saveStockAdjustmentAfterSavingItems = null;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao salvar os itens");
            }
          });
      }
    },

    changeStockAdjustmentItemsPageWithoutChecking() {
      this.stockAdjustmentItemsPageChanged(
        this.stockAdjustmentItemsTempPage,
        true
      );
      this.stockAdjustmentItemsTempPage = null;
    },

    stockAdjustmentItemsPerPageChanged() {
      this.stockAdjustmentItemsPageChanged(1);
    },

    readStockAdjustmentItemLotBarcode(barcode) {
      if (barcode !== undefined) {
        this.barcodeStockAdjustmentItemItemLotBarcode = barcode;
        this.$bvModal.hide("header-item-lot-barcode-input-modal");
      }

      this.stockAdjustmentItemsFoundByBarcode = [];

      if (!this.loadingStockAdjustmentItemLotBarcode) {
        if (
          this.barcodeStockAdjustmentItemItemLotBarcode &&
          this.barcodeStockAdjustmentItemItemLotBarcode.length
        ) {
          this.loadingStockAdjustmentItemLotBarcode = true;
          httpClient
            .get(
              `${process.env.VUE_APP_API_URL}item-lots/barcode/${this.barcodeStockAdjustmentItemItemLotBarcode}`
            )
            .then((data) => {
              this.loadingStockAdjustmentItemLotBarcode = false;

              if (data.data.data) {
                let itemLot = data.data.data;
                let conditions = [
                  {
                    field: "stockAdjustment.id",
                    value: this.formData.id,
                  }
                ];

                if (itemLot.item && itemLot.item.automaticItemLot) {
                  this.$notify.success("Item encontrado. Buscando estoques do pedido com esse item.");
                  conditions.push({
                    field: "item.id",
                    value: itemLot.item.id,
                  });
                } else {
                  this.$notify.success("Lote encontrado. Buscando itens do pedido com esse lote.");
                  conditions.push({
                    field: "itemLot.id",
                    value: itemLot.id,
                  });
                }

                this.loadingStockAdjustmentItemLotBarcode = true;
                httpClient
                  .post(
                    `${
                      process.env.VUE_APP_API_URL
                    }stock-adjustment-items/condition?page=${0}&size=${10000}`,
                    {
                      conditions: conditions,
                    }
                  )
                  .then(({ data }) => {
                    this.loadingStockAdjustmentItemLotBarcode = false;

                    if (data.data.content && data.data.content.length) {
                      this.stockAdjustmentItemsFoundByBarcode =
                        data.data.content;

                      this.stockAdjustmentItemsFoundByBarcode.forEach((sai) => {
                        if (
                          sai.itemLot &&
                          sai.itemLot.quantityPerVolume &&
                          (this.incrementQuantity ||
                            sai.quantity === null ||
                            sai.quantity === undefined ||
                            !sai.quantity.toString().length)
                        ) {
                          sai.newQuantity = sai.itemLot.quantityPerVolume;
                          sai.numberOfVolumes = 1;
                        } else {
                          if (!this.incrementQuantity) {
                            sai.newQuantity = sai.quantity;
                          } else if (sai.item && sai.item.automaticItemLot) {
                            sai.newQuantity = 1;
                          } else {
                            sai.newQuantity = null;
                          }

                          sai.numberOfVolumes = 0;
                        }
                      });

                      this.$notify.success("Itens encontrados");

                      if (
                        this.usePreviousLocation &&
                        this.previousLocation &&
                        this.previousLocation.barcode &&
                        this.previousLocation.barcode.length
                      ) {
                        this.processStockAdjustmentsItemFoundLocation(
                          this.previousLocation
                        );
                      } else {
                        this.focusOnStockAdjustmentItemLocationBarcodeInput();
                      }
                    } else {
                      this.$notify.textError(
                        "Não foram encontrados estoques desse pedido com esse lote/item"
                      );
                      this.focusOnStockAdjustmentItemItemLotBarcodeInput();
                    }
                  })
                  .catch((error) => {
                    this.loadingStockAdjustmentItemLotBarcode = false;
                    if (error.message) {
                      this.$notify.error(error);
                    }

                    this.focusOnStockAdjustmentItemItemLotBarcodeInput();
                  });
              } else {
                this.$notify.textError("Lote/Item não encontrado");
                this.focusOnStockAdjustmentItemItemLotBarcodeInput();
              }
            })
            .catch((error) => {
              this.loadingStockAdjustmentItemLotBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente"
                );
              }

              this.focusOnStockAdjustmentItemItemLotBarcodeInput();
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }
    },

    focusOnStockAdjustmentItemItemLotBarcodeInput() {
      setTimeout(() => {
        this.stockAdjustmentItemsFoundByBarcode = [];
        this.barcodeStockAdjustmentItemItemLotBarcode = null;

        if (!this.usePreviousLocation)
          this.barcodeStockAdjustmentItemLocationBarcode = null;

        if (this.useCamera && this.autoOpenCamera) {
          this.openHeaderItemLotBarcodeInputModal();
        } else if (this.$refs.selectStockAdjustmentItemByBarcodeItemLotBarcodeInput) {
          this.$refs.selectStockAdjustmentItemByBarcodeItemLotBarcodeInput.$el.focus();
        }
      }, 2);
    },

    focusOnStockAdjustmentItemLocationBarcodeInput() {
      setTimeout(() => {
        this.barcodeStockAdjustmentItemLocationBarcode = null;
        if (this.useCamera && this.autoOpenCamera) {
          this.openHeaderLocationBarcodeInputModal();
        } else if (this.$refs.selectStockAdjustmentItemByBarcodeLocationBarcodeInput) {
          this.$refs.selectStockAdjustmentItemByBarcodeLocationBarcodeInput.$el.focus();
        }
      }, 2);
    },

    cancelStockAdjustmentItemsFoundByBarcode() {
      this.stockAdjustmentItemsFoundByBarcode = [];
      this.barcodeStockAdjustmentItemItemLotBarcode = null;

      if (!this.usePreviousLocation)
        this.barcodeStockAdjustmentItemLocationBarcode = null;

      this.isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity = false;
    },

    readStockAdjustmentItemLocationBarcode(barcode) {
      if (barcode !== undefined) {
        this.barcodeStockAdjustmentItemLocationBarcode = barcode;
        this.$bvModal.hide("header-location-barcode-input-modal");
      }

      if (!this.loadingStockAdjustmentItemLocationBarcode) {
        if (
          this.barcodeStockAdjustmentItemLocationBarcode &&
          this.barcodeStockAdjustmentItemLocationBarcode.length
        ) {
          this.loadingStockAdjustmentItemLocationBarcode = true;

          httpClient
            .get(
              `${process.env.VUE_APP_API_URL}locations/barcode/${this.barcodeStockAdjustmentItemLocationBarcode}`
            )
            .then((data) => {
              this.loadingStockAdjustmentItemLocationBarcode = false;

              let ok = false;
              if (data.data.data)
                ok = this.processStockAdjustmentsItemFoundLocation(
                  data.data.data
                );
              else this.$notify.textError("Endereço não encontrado");

              if (!ok) this.focusOnStockAdjustmentItemLocationBarcodeInput();
            })
            .catch((error) => {
              this.loadingStockAdjustmentItemLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error);
              } else {
                this.$notify.textError(
                  "Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente"
                );
              }

              this.focusOnStockAdjustmentItemLocationBarcodeInput();
            });
        } else {
          this.$notify.textError(
            "Código inválido. Por favor insira um código de barras válido"
          );
        }
      }
    },

    processStockAdjustmentsItemFoundLocation(location) {
      this.$notify.success("Endereço encontrado.");

      if (
        this.isWarehouseSelected &&
        location.stockLocale &&
        this.selectedWarehouse.id !== location.stockLocale.id
      ) {
        this.$notify.textError("Endereço não é compatível com este armazém");
      } else {
        let newSAIFBB = this.stockAdjustmentItemsFoundByBarcode.filter(
          (sai) => sai.location && sai.location.id === location.id
        );

        if (newSAIFBB.length) {
          this.previousLocation = location;

          this.$notify.success("Itens encontrados");
          this.stockAdjustmentItemsFoundByBarcode = newSAIFBB;
          this.$refs.stockAdjustmentItemsFoundByBarcodeTable.focusOnFirstStockAdjustmentItemFoundByBarcodeQuantityField();
          return true;
        } else {
          this.$notify.textError(
            "Esse endereço não consta nos itens encontrados pelo lote"
          );
        }
      }

      return false;
    },

    stockAdjustmentItemsFoundByBarcodeQuantityChanged(index) {
      if (!this.incrementQuantity) {
        this.isThereAnyStockAdjustmentItemFoundByBarcodeWithChangedQuantity = true;
        this.stockAdjustmentItemsFoundByBarcode[index].quantityChanged = true;
        this.stockAdjustmentItemsFoundByBarcode[
          index
        ].quantity = this.stockAdjustmentItemsFoundByBarcode[index].newQuantity;

        if (
          this.stockAdjustmentItemsFoundByBarcode[index].quantity >
          this.stockAdjustmentItemsFoundByBarcode[index].currentQuantity
        ) {
          this.stockAdjustmentItemsFoundByBarcode[index].generatedType = "ENT";
        } else if (
          this.stockAdjustmentItemsFoundByBarcode[index].currentQuantity >
          this.stockAdjustmentItemsFoundByBarcode[index].quantity
        ) {
          this.stockAdjustmentItemsFoundByBarcode[index].generatedType = "SAI";
        }

        let sai = this.stockAdjustmentItemsFoundByBarcode;
        this.stockAdjustmentItemsFoundByBarcode = [];

        this.$nextTick(() => {
          this.stockAdjustmentItemsFoundByBarcode = sai;
        });
      }
    },

    openOrderAttendancesModal() {
      this.$bvModal.show("order-attendances-modal");
      this.$refs.orderAttendancesModal.orderAttendancesPageChanged(1);
    },

    toggleShowItemForm() {
      if (
        this.formData &&
        this.formData.countOption &&
        this.formData.countOption !== "E"
      ) {
        this.showItemForm = !this.showItemForm;
      }
    },

    setIsUserManager() {
      if (
        this.formData &&
        (!this.formData.countOption || this.formData.countOption === "E")
      ) {
        this.isUserManager = true;
      } else {
        if (
          this.authUser &&
          this.authUser.profile &&
          this.authUser.profile.modules
        ) {
          let adjustmentsProfileModule = this.authUser.profile.modules.find(
            (pm) => {
              return (
                pm.name && pm.name.toString().toUpperCase() == "ADJUSTMENTS"
              );
            }
          );

          this.isUserManager =
            adjustmentsProfileModule && adjustmentsProfileModule.canExecute;
        }
      }
    },

    openReturnToPendingConfirmationModal() {
      this.$bvModal.show("return-to-pending-confirmation-modal");
    },

    returnToPending() {
      this.save({
        returnToPending: true,
        leavePage: true,
      });
    },

    operationBadgeClassObject(item) {
      return shared.operationBadgeClassObject(item);
    },

    updateDuplicatedSAI(replaceQuantity) {
      if (this.formData.id) {
        this.duplicateSAI.stockAdjustment = { id: this.formData.id };
      }

      if (!this.duplicateSAI.quantity) {
        this.duplicateSAI.quantity = 0;
      }

      if (!this.quantity) {
        this.quantity = 0;
      }

      if (replaceQuantity) {
        this.duplicateSAI.quantity = Number(this.quantity);
      } else {
        this.duplicateSAI.quantity =
          Number(this.duplicateSAI.quantity) + Number(this.quantity);
      }

      if (
        this.duplicateSAI.itemLot &&
        this.duplicateSAI.itemLot.quantityPerVolume
      ) {
        this.duplicateSAI.numberOfVolumes = Math.floor(
          shared.divide(
            this.duplicateSAI.quantity,
            this.duplicateSAI.itemLot.quantityPerVolume
          )
        );
      }

      if (this.duplicateSAI.quantity > this.balanceQuantity) {
        this.duplicateSAI.generatedType = "ENT";
      } else if (this.balanceQuantity > this.quantity) {
        this.duplicateSAI.generatedType = "SAI";
      }

      if (
        this.duplicateSAI.id &&
        this.formData.countOption &&
        this.formData.countOption !== "E"
      ) {
        this.saving = true;
        httpClient
          .put(
            `${process.env.VUE_APP_API_URL}stock-adjustment-items`,
            this.duplicateSAI
          )
          .then((data) => {
            this.saving = false;
            this.$notify.success("Item editado com sucesso");

            let index = this.formData.stockAdjustmentItems.findIndex(
              (sai) => sai.id === data.data.data.id
            );

            if (index > -1) {
              let currentQuantity = this.formData.stockAdjustmentItems[index]
                .currentQuantity;
              let generatedType = this.formData.stockAdjustmentItems[index]
                .generatedType;
              let numberOfVolumes = this.formData.stockAdjustmentItems[index]
                .numberOfVolumes;
              this.formData.stockAdjustmentItems[index] = data.data.data;
              this.formData.stockAdjustmentItems[
                index
              ].currentQuantity = currentQuantity;
              this.formData.stockAdjustmentItems[
                index
              ].generatedType = generatedType;
              this.formData.stockAdjustmentItems[
                index
              ].numberOfVolumes = numberOfVolumes;
              this.handleStockAdjustmentItemSaved();
            }

            this.showDuplicateItemModal = false;
            this.$bvModal.hide("duplicate-item-modal");
          })
          .catch((error) => {
            this.saving = false;
            if (error.message) {
              this.$notify.error(error);
            } else {
              this.$notify.textError("Houve um erro ao salvar o item");
            }
          });
      } else {
        let index = this.formData.stockAdjustmentItems.findIndex((sai) => {
          return (
            sai.item &&
            sai.itemLot &&
            sai.location &&
            sai.item.id === this.selectedItem.id &&
            sai.itemLot.id === this.selectedLot.id &&
            sai.location.id === this.selectedLocation.id
          );
        });

        if (index === -1) {
          index = this.formData.stockAdjustmentItems.length;
        }

        this.formData.stockAdjustmentItems[index] = this.duplicateSAI;
        this.handleStockAdjustmentItemSaved();
        this.showDuplicateItemModal = false;
        this.$bvModal.hide("duplicate-item-modal");
      }
    },

    updateInconsistentBalances(updatedBalances) {
      if (updatedBalances && updatedBalances.length && this.formData && this.formData.stockAdjustmentItems) {
        let atLeastOneUpdated = false;

        updatedBalances.forEach(balance => {
          let index = this.formData.stockAdjustmentItems.findIndex(sai => {
            return sai.itemLot && sai.itemLot.id === balance.itemLot.id
              && sai.location && sai.location.id === balance.location.id;
          });

          if (index > -1) {
            this.formData.stockAdjustmentItems[index].currentQuantity = balance.currentQuantity;
            atLeastOneUpdated = true;
          }
        });

        if (atLeastOneUpdated) {
          this.reloadFormData();
        }
      }
    },

    openHeaderItemLotBarcodeInputModal() {
      this.$bvModal.show("header-item-lot-barcode-input-modal");
    },

    openHeaderLocationBarcodeInputModal() {
      this.$bvModal.show("header-location-barcode-input-modal");
    }
  },

  watch: {
    selectedItem: function (newVal, oldVal) {
      if (newVal && Object.keys(newVal).length > 0) {
        this.searchLocation(null);
        this.searchLot(null);
      }
    },
    selectedWarehouse: function (newVal, oldVal) {
      if (newVal && Object.keys(newVal).length > 0) {
        this.searchLocation(null);
      }
    },
    selectedLot: function (newVal, oldVal) {
      if (
        newVal &&
        Object.keys(newVal).length > 0 &&
        !this.isItemSelected &&
        newVal.item
      ) {
        this.setItemLabel(newVal.item);
        this.items.push(newVal.item);
        this.selectedItem = newVal.item;
      }
    },
    selectedLocation: function (newVal, oldVal) {
      if (
        newVal &&
        Object.keys(newVal).length > 0 &&
        !this.isItemSelected &&
        newVal.item
      ) {
        this.setItemLabel(newVal.item);
        this.items.push(newVal.item);
        this.selectedItem = newVal.item;
      }
    },

    numberOfVolumes(newValue, oldValue) {
      if (
        newValue &&
        this.isLotSelected &&
        this.selectedLot &&
        this.selectedLot.quantityPerVolume
      ) {
        this.$set(
          this,
          "quantity",
          this.selectedLot.quantityPerVolume * newValue
        );
      }
    },

    usePreviousLocation(newValue, oldValue) {
      if (newValue) {
        if (
          this.previousLocation &&
          this.previousLocation.barcode &&
          this.previousLocation.barcode.length
        )
          this.barcodeStockAdjustmentItemLocationBarcode = this.previousLocation.barcode;
      } else this.barcodeStockAdjustmentItemLocationBarcode = null;
    },
  },
};
</script>

<style scoped>
</style>
