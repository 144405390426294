<template>
  <form-location-and-lot-adjustments />
</template>

<script>
  import FormLocationAndLotAdjustments from '@/components/forms/form-location-and-lot-adjustments'

  export default {
    name: 'location-and-lot-adjustment',
    components: { FormLocationAndLotAdjustments },
  }
</script>

<style scoped>

</style>
