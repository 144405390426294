<template>
  <b-modal
    size="xl"
    centered
    lazy
    title="Verificar inconsistências"
    header-bg-variant="info"
    footer-border-variant="info"
    v-model="show"
    :hide-footer="true"
  >
    <b-container>
      <p>Esta rotina verifica se há alguma inconsistência entre os estoques (saldos) dos itens desse ajuste e seus movimentos</p>
      <p>Esta rotina <b>NÃO</b> verifica divergências entre as contagens do ajuste e as quantidades do sistema</p>
      <p>Dependendo do número de itens do ajuste e da quantidade de movimentos de cada um o processo pode demorar um pouco para ser executado</p>

      <div class="row justify-content-center">
        <div class="col-auto">
          <animation v-if="loading"/>
          <b-button v-else variant="primary" @click="check">Verificar</b-button>
        </div>
      </div>

      <div v-if="!loading && isVerificationStillRunning" class="row justify-content-center my-3">
        <div class="col-auto">
          <h4>
            A verificação está sendo executada. Aguarde alguns instantes.
          </h4>
        </div>
      </div>

      <div v-if="!loading && noInconsistentBalanceFound" class="row justify-content-center my-3">
        <div class="col-auto">
          <h4>
            <i class="fa fa-check text-success"></i>
            Nenhum dos itens do ajuste está inconsistente
          </h4>
        </div>
      </div>

      <div v-if="!loading && inconsistentBalances.length">
        <b>Estoques inconsistentes:</b>

        <div v-for="(balance, index) in inconsistentBalances" class="row py-3 border mx-0 align-items-lg-start align-items-end" :key="index"
          :class="{ 'bg-light': index % 2 != 0 }">

          <div class="col-xl-6 col-lg-6 col-12">
            <div class="row">
              <div class="col-12">
                <span class="font-weight-bold">Item: </span>{{balance.itemLot && balance.itemLot.item ? (getItemReduzidoFromRef(balance.itemLot.item.ref) + ' - ' + balance.itemLot.item.description) : ''}}
              </div>
              <div class="col-xl-6 col-lg-12 col-sm-6 col-12">
                <span class="font-weight-bold">Endereço: </span>{{balance.location ? balance.location.name : ''}}
              </div>
              <div class="col-xl-6 col-lg-12 col-sm-6 col-12">
                <span class="font-weight-bold">Lote: </span>{{balance.itemLot ? balance.itemLot.lotNumber : ''}}
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-sm-8 col-9 font-weight-bold">
            <div class="row">
              <div class="col-12">
                <span>Saldos: </span>
              </div>
              <div class="col-12">
                <span>Estoque: </span>
                <span v-if="balance.currentQuantity > balance.movementsQuantity" class="text-success">
                  {{balance.currentQuantity}}
                </span>
                <span v-if="balance.currentQuantity < balance.movementsQuantity" class="text-danger">
                  {{balance.currentQuantity}}
                </span>
              </div>
              <div class="col-12">
                <span>Movimentos: </span>
                <span v-if="balance.movementsQuantity > balance.currentQuantity" class="text-success">
                  {{balance.movementsQuantity}}
                </span>
                <span v-if="balance.movementsQuantity < balance.currentQuantity" class="text-danger">
                  {{balance.movementsQuantity}}
                </span>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-sm-4 col-3">
            <div class="row align-items-start">
              <div class="col-auto pr-2 mr-n3 cursor-pointer">
                <b-form-checkbox v-model="balance.confirm" switch size="lg" class="mb-xl-0 mb-n2"></b-form-checkbox>
              </div>
              <div class="col-auto pl-1 mobile-hide pt-1">
                <label>Confirmar</label>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center mt-3">
          <div class="col-auto">
            <b-button variant="warning" @click="openCorrectionConfirmationModal">
              Corrigir saldo do estoque baseado no saldo de movimentos
            </b-button>
          </div>
        </div>
      </div>

      <confirmation-modal
        id="correction-confirmation-modal"
        msg="Esse processo irá ajustar a quantidade em estoque do sistema de acordo com o saldo de movimentos de cada estoque confirmado. Tem certeza de que deseja continuar?"
        :ok="correct"
      ></confirmation-modal>

      <confirmation-modal
        id="create-recount-stock-adjustment-confirmation-modal"
        msg="Deseja criar um novo ajuste para recontar os itens que estavam com inconsistências?"
        :ok="createRecountStockAdjustment"
        :cancel="hideModal"
      ></confirmation-modal>
    </b-container>
  </b-modal>
</template>

<script>
  import Animation from "@/components/loaders/animation";
  import { httpClient } from "@/service";
  import shared from '@/shared/shared';
  import ConfirmationModal from "@/components/common/confirmation-modal";

  export default {
    name: 'balance-inconsistency-check-modal',
    components: {
      Animation,
      ConfirmationModal
    },

    props: [
      'entity',
      'updateBalances'
    ],

    data() {
      return {
        show: false,
        loading: false
      }
    },

    computed: {
      inconsistentBalances() {
        if (
          this.entity &&
          this.entity.processData &&
          this.entity.processData.inconsistentBalances &&
          this.entity.processData.inconsistentBalances.length
        ) {
          return this.entity.processData.inconsistentBalances;
        }

        return [];
      },

      noInconsistentBalanceFound() {
        return this.entity &&
          this.entity.processStatus === 'IF' &&
          this.entity.processData &&
          this.entity.processData.inconsistentBalancesSize === 0;
      },

      isVerificationStillRunning() {
        return this.entity && this.entity.processStatus === 'VI';
      }
    },

    methods: {
      showModal() {
        this.show = true;
      },

      hideModal() {
        this.show = false;
      },

      check() {
        this.loading = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}stock-adjustments/balance-inconsistency-check/${this.entity.id}`)
        .then(({ data }) => {
          this.loading = false;
          this.updateEntityProperties(data.data);
          this.$notify.warn('Solicitação de verificação de inconsistências enviada');
        })
        .catch((error) => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError('Ocorreu um erro');
          }
        });
      },

      openCorrectionConfirmationModal() {
        this.$bvModal.show("correction-confirmation-modal");
      },

      correct() {
        if (!this.inconsistentBalances.find(b => b.confirm)) {
          this.$notify.textError('Nenhum estoque confirmado');
          return;
        }

        this.loading = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}stock-adjustments/balance-inconsistency/confirmation/${this.entity.id}`,
          this.inconsistentBalances.filter(b => b.confirm)
        ).then(({ data }) => {
          this.loading = false;
          this.updateEntityProperties(data.data.stockAdjustment);

          if (this.updateBalances) {
            this.updateBalances(data.data.balances);
          }

          this.$notify.success('Estoques corrigidos');
          this.$bvModal.show("create-recount-stock-adjustment-confirmation-modal");
        })
        .catch((error) => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError('Ocorreu um erro');
          }
        });
      },

      createRecountStockAdjustment() {
        this.loading = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}stock-adjustments/balance-inconsistency/${this.entity.id}/recount`,
          this.inconsistentBalances
        ).then(({ data }) => {
          this.loading = false;
          this.$notify.success('Ajuste de recontagem criado com sucesso');
          window.open(
            "/orders/location-and-lot-item-adjustment/edit/" +
            data.data.id
          );
          this.hideModal();
        })
        .catch((error) => {
          this.loading = false;
          if (error.message) {
            this.$notify.error(error);
          } else {
            this.$notify.textError('Ocorreu um erro');
          }
        });
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      updateEntityProperties(newEntity) {
        if (this.entity && newEntity) {
          Object.keys(newEntity).forEach(key => {
            this.entity[key] = newEntity[key];
          })
        }
      }
    }
  }
</script>
