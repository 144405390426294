<template>
  <div>
    <div v-for="(orderItem, index) in stockAdjustmentItems" class="row py-3 border mx-0" :key="index"
      :class="{ 'bg-light': (!orderItem.countStatus || orderItem.countStatus === 'BLANK') && index % 2 != 0, 'count-correct': orderItem.countStatus === 'ACCORDING' }">
      <div class="col-xl col-md-4 col-12 mb-md-0 mb-1">
        <div class="row">
          <div class="col-12">
            <span class="font-weight-bold">Item: </span>{{orderItem.item ? (getItemReduzidoFromRef(orderItem.item.ref) + ' - ' + orderItem.item.description) : ''}}
          </div>
          <div class="col-6">
            <span class="font-weight-bold">Endereço: </span>{{orderItem.location ? orderItem.location.name : ''}}
          </div>
          <div v-if="!orderItem.item || !orderItem.item.automaticItemLot" class="col-6">
            <span class="font-weight-bold">Lote: </span>{{orderItem.itemLot ? orderItem.itemLot.lotNumber : ''}}
          </div>
        </div>
      </div>
      <div class="col-sm col-12 mb-sm-0 mb-3">
        <div class="row">
          <div v-if="showCurrentQuantity" class="col-xl-12 col-4">
            <span class="font-weight-bold">Qtd. antiga: </span>
            <composite-item-lot-quantity
              :quantity="orderItem.currentQuantity"
              :itemLot="orderItem.itemLot"
            ></composite-item-lot-quantity>
          </div>
          <div v-if="showCurrentQuantity && orderItem.generatedType && Math.abs(orderItem.quantity - (orderItem.currentQuantity || 0)) > 0"
            class="col-xl-12 col-4 font-weight-bold">
            <span v-if="orderItem.generatedType === 'ENT'" class="text-success">
              <span>Diferença: </span>
              +{{Math.abs(orderItem.quantity - (orderItem.currentQuantity || 0))}}
            </span>
            <span v-else-if="orderItem.generatedType === 'SAI'" class="text-danger">
              <span>Diferença: </span>
              -{{Math.abs(orderItem.quantity - (orderItem.currentQuantity || 0))}}
            </span>
          </div>
          <div class="col-12">
            <span v-if="!formData.countOption || formData.countOption === 'E'">
              <span class="font-weight-bold">Qtd. nova: </span>{{orderItem.quantity}}
            </span>
            <div v-else class="row align-items-center">
              <div v-if="incrementQuantity" class="col-12 mt-2">
                <span class="font-weight-bold">Qtd. nova:</span>
              </div>
              <div v-if="incrementQuantity" class="col-12 mb-2 pr-2">
                <composite-item-lot-quantity
                  :quantity="orderItem.quantity"
                  :itemLot="orderItem.itemLot"
                ></composite-item-lot-quantity>
                <span class="h5 font-weight-bold text-success"> +</span>
              </div>
              <div class="col" :class="incrementQuantity ? 'pl-0' : ''">
                <div class="row align-items-center">
                  <div v-if="orderItem && orderItem.itemLot && orderItem.itemLot.quantityPerVolume" class="col">
                    <b-input v-model="orderItem.numberOfVolumes" :id="'numberOfVolumes-' + orderItem.id" placeholder="N. volumes"
                    onFocus="this.setSelectionRange(0, this.value.length)" ref="numberOfVolumesFields"
                    :disabled="saving || loadingStockAdjustmentItems || Boolean(stockAdjustmentItemsFoundByBarcode &&
                    stockAdjustmentItemsFoundByBarcode.length) || !allowEdit || (formData.movementStatus !== 'C' && orderItem.countStatus === 'ACCORDING')"
                    @blur="setNumberOfVolumes(index, isFoundByBarcode, quantityFieldName)"
                    :class="{ 'count-divergent': orderItem.countStatus === 'DIVERGENT' }">
                    </b-input>
                  </div>
                  <div v-if="orderItem && orderItem.itemLot && orderItem.itemLot.quantityPerVolume" class="col-auto px-0">
                    <span class="text-muted">x </span> {{ orderItem.itemLot.quantityPerVolume }} =
                  </div>
                  <div class="col">
                    <b-input v-model="orderItem[quantityFieldName]" :id="'quantity-' + orderItem.id"
                    :placeholder="incrementQuantity ? 'Qtd. adicional' : 'Qtd. nova'"
                    onFocus="this.setSelectionRange(0, this.value.length)"
                    @change="quantityChanged(index)" ref="newQuantityFields" @keydown.enter="saveStockAdjustmentItems([orderItem], true)"
                    :disabled="saving || loadingStockAdjustmentItems || Boolean(
                      stockAdjustmentItemsFoundByBarcode && stockAdjustmentItemsFoundByBarcode.length) || !allowEdit || (formData.movementStatus !== 'C' && orderItem.countStatus === 'ACCORDING')
                    " :class="{ 'count-divergent': orderItem.countStatus === 'DIVERGENT' }">
                    </b-input>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="orderItem.quantityChanged" class="mt-2">
              <span class="font-weight-bold text-danger">
                <i class="fa fa-exclamation-triangle"></i> Não salvo
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="allowEdit && (editItem || openDeleteModal || saveStockAdjustmentItems)" class="col-sm-auto col-12">
        <b-button v-if="editItem && (!formData.countOption || formData.countOption === 'E')" size="sm" @click.prevent="editItem(index)"
          :disabled="editIndex === index"
          class="mr-sm-1 mr-4 mobile-btn-45">
          <i class="fa fa-edit"></i> Editar
        </b-button>
        <b-button v-if="openDeleteModal" size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
          class="mobile-btn-45"
          :disabled="saving || loadingStockAdjustmentItems || Boolean(stockAdjustmentItemsFoundByBarcode && stockAdjustmentItemsFoundByBarcode.length)">
          <i class="fa fa-trash"></i> Excluír
        </b-button>
        <div class="row align-items-end h-100">
          <div class="col">
            <b-button v-if="saveStockAdjustmentItems" variant="primary" @click.prevent="saveStockAdjustmentItems([orderItem], true)"
            :disabled="saving || loadingStockAdjustmentItems" class="align-bottom">
              Salvar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import shared from '@/shared/shared';
  import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';

  export default {
    name: 'eel-stock-adjustment-items-table',
    components: { CompositeItemLotQuantity },

    props: {
      formData: {
        type: Object,
        require
      },
      stockAdjustmentItems: {
        type: Array,
        require
      },
      quantityChanged: {
        type: Function,
        default () {
          return;
        },
      },
      setNumberOfVolumes: {
        type: Function,
        default () {
          return;
        }
      },
      isFoundByBarcode: {
        type: Boolean
      },
      saving: {
        type: Boolean
      },
      loadingStockAdjustmentItems: {
        type: Boolean
      },
      stockAdjustmentItemsFoundByBarcode: {
        type: Array
      },
      editIndex: {
        type: Number
      },
      editItem: {
        type: Function
      },
      openDeleteModal: {
        type: Function
      },
      saveStockAdjustmentItems: {
        type: Function
      },
      showCurrentQuantity: {
        type: Boolean,
        require
      },
      allowEdit: {
        type: Boolean,
        require
      },
      incrementQuantity: {
        type: Boolean
      },
      quantityFieldName: {
        type: String,
        require
      },
      numberOfVolumes: Number
    },

    methods: {
      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      focusOnFirstStockAdjustmentItemFoundByBarcodeQuantityField() {
        setTimeout(() => {
          if (this.stockAdjustmentItems && this.stockAdjustmentItems.length) {
            if (this.stockAdjustmentItems[0].itemLot && this.stockAdjustmentItems[0].itemLot.quantityPerVolume) {
              if (this.$refs.numberOfVolumesFields && this.$refs.numberOfVolumesFields.length) {
                this.$refs.numberOfVolumesFields[0].$el.focus();
              }
            } else {
              if (this.$refs.newQuantityFields && this.$refs.newQuantityFields.length) {
                this.$refs.newQuantityFields[0].$el.focus();
              }
            }
          }
        }, 2);
      }
    }
  }
</script>

<style scoped>
.count-divergent {
  /*background-color: #ff806f;*/
  border: 3px solid red !important;
  /*border-width: 5px !important;
  border-color: #f86c6b !important;
  border-style: outset outset none outset !important;*/
}

.count-correct {
  background-color: #93cf84;
}
</style>
